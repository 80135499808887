import ApiService from '@/assets/js/services/api.service';

export default {
  state: {
    goodsCategories: [],
    goodsCategoriesForCreation: [],
    partnerCategories: []
  },
  mutations: {
    setGoodsCategories(state, payload) {
      state.goodsCategories = payload;
    },
      setGoodsCategoriesForCreation(state, payload) {
          state.goodsCategoriesForCreation = payload;
      },
      setPartnerCategories(state, payload) {
          state.partnerCategories = payload;
      }
  },
  actions: {
    loadGoodsCategories({ commit }) {
      return new Promise((resolve, reject) => {
        ApiService.get('api/client/goods/categories')
          .then((result) => {
            commit('setGoodsCategories', result.data);
            resolve(true)
          });
      });
    },
      loadGoodsCategoriesForCreation({ commit }) {
          return new Promise((resolve, reject) => {
              ApiService.get('api/client/goods/categories?type=create')
                  .then((result) => {
                      commit('setGoodsCategoriesForCreation', result.data);
                      resolve(true)
                  });
          });
      },
      loadPartnerCategories({ commit }) {
          return new Promise((resolve, reject) => {
              ApiService.get('api/client/partners/categories')
              .then((result) => {
              commit('setPartnerCategories', result.data);
          resolve(true)
      });
      });
      }
  },
  getters: {
    goodsCategories(state) {
      return state.goodsCategories;
    },
      goodsCategoriesForCreation(state) {
          return state.goodsCategoriesForCreation;
      },
      partnerCategories(state) {
          return state.partnerCategories;
      }
  }
};
