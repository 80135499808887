<template>
  <div class="modal-wrapper modal-code" id="modal-edit-confirmation">
    <div class="modal">
      <div class="contents">
        <modal-close-button/>

        <div class="h1">{{ $t("modal.confirm.confirm") }}</div>
        <div class="h2">{{ $t("modal.confirm.enter-code") }}</div>
        <div class="form" id="form-confirmation-code">
          <form action="#" method="post" onsubmit="return false;">
            <!-- ДЕМО КОД 0000 -->
            <div class="code-holder">
              <input type="number" name="digit1" :value="codeNumbers.n1" min="0" max="9" maxlength="1"
                     autocomplete="off" ref="code1" @input="setN1" :class="{'err': error}">
              <input type="number" name="digit2" :value="codeNumbers.n2" min="0" max="9" maxlength="1"
                     autocomplete="off" ref="code2" @input="setN2" :class="{'err': error}">
              <input type="number" name="digit3" :value="codeNumbers.n3" min="0" max="9" maxlength="1"
                     autocomplete="off" ref="code3" @input="setN3" :class="{'err': error}">
              <input type="number" name="digit4" :value="codeNumbers.n4" min="0" max="9" maxlength="1"
                     autocomplete="off" ref="code4" @input="setN4" :class="{'err': error}">
            </div>
            <div class="message error" v-if="error">{{ $t("modal.confirm.wrong-code") }}</div>
            <a href="#" class="repeat" @click="resendCode">{{ $t("modal.confirm.resend") }}</a>
          </form>
        </div>
        <div class="note">{{ $t("modal.confirm.check-spam") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { eventBus } from '../../../main';
  import modal from '../../../assets/js/init/modal';
  import confirmRegistrationModal from '../../../assets/js/init/confirmRegistrationModal';
  import messages from '../../../assets/js/messages';
  import { mapActions, mapGetters } from 'vuex';
  import ModalCloseButton from '../../layouts/ModalCloseButton';

  export default {
    name: 'ProfileEditConfirmationModal',
    components: { ModalCloseButton },
    data() {
      return {
        email: null,
        password: null,
        error: false,
        codeNumbers: {
          n1: null,
          n2: null,
          n3: null,
          n4: null,
        }
      };
    },
    computed: {
      ...mapGetters(['user'])
    },
    created() {
      eventBus.$on('onEditPasswordConfirmation', (password) => {
        this.clearData();

        this.password = password;
        modal.openModal('modal-edit-confirmation');
      });
      eventBus.$on('onEditEmailConfirmation', (email) => {
        this.clearData();

        this.email = email;
        modal.openModal('modal-edit-confirmation');
      });
      confirmRegistrationModal.init(this.checkAndSendCode);
    },
    methods: {
      ...mapActions(['changePassword', 'sendVerificationCodeForSignIn', 'sendVerificationCodeForPasswordChanging', 'saveUserData']),
      setN1(event) {
        this.codeNumbers.n1 = event.data;
        this.$forceUpdate();
        this.$refs.code2.focus();
        this.sendCode();
      },
      setN2(event) {
        this.codeNumbers.n2 = event.data;
        this.$forceUpdate();
        this.$refs.code3.focus();
        this.sendCode();
      },
      setN3(event) {
        this.codeNumbers.n3 = event.data;
        this.$forceUpdate();
        this.$refs.code4.focus();
        this.sendCode();
      },
      setN4(event) {
        this.codeNumbers.n4 = event.data;
        this.$forceUpdate();
        this.$refs.code4.blur();
        this.sendCode();
      },
      clearData() {
        this.password = null;
        this.email = null;
        this.error = false;
        this.codeNumbers.n1 = null;
        this.codeNumbers.n2 = null;
        this.codeNumbers.n3 = null;
        this.codeNumbers.n4 = null;
      },
      resendCode() {
        if(this.email) {
          this.saveUserData({ email: this.email })
            .catch((error) => {
              this.showToast(error.response.data.message, 'error');
            });
        } else if(this.password) {
          this.sendVerificationCodeForPasswordChanging(this.user.email)
            .catch((error) => {
              this.showToast(error.response.data.message, 'error');
            });;
        }
      },

      sendCode() {
        this.error = false;
        if (this.codeNumbers.n1 === null || this.codeNumbers.n2 === null || this.codeNumbers.n3 === null || this.codeNumbers.n4 === null) {
          return;
        }
        let code = '' + this.codeNumbers.n1 + this.codeNumbers.n2 + this.codeNumbers.n3 + this.codeNumbers.n4;
        if (this.email !== null) {
          this.sendVerificationCodeForSignIn({
            identifierValue: this.email,
            verificationCode: code,
            identifierTypeId: '54480ce1-00eb-4179-a2b6-f74daa6b9e72'
          })
            .then((result) => {
              this.showToast(this.$t('toast.email-changed'));
              modal.hideModalWithoutFadeout();
              this.password = null;
              this.email = null;
            })
            .catch(({ result }) => {
              this.error = true;
            });
        }
        if (this.password !== null) {
          this.changePassword({
            identifierValue: this.user.email,
            newPassword: this.password,
            verificationCode: code,
            identifierTypeId: '54480ce1-00eb-4179-a2b6-f74daa6b9e72'
          })
            .then((result) => {
              if (result) {
                this.showToast(this.$t('toast.password-changed'));
              }
            })
            .catch(({ result }) => {
              this.error = true;
            });
        }

      },
    }
  };
</script>

<style scoped>

</style>
