import ApiService from '@/assets/js/services/api.service';

export default {
  state: {

  },
  mutations: {

  },
  actions: {
    saveUserData({ commit }, data) {
      return ApiService.post('api/client/user', data);
    },
    deleteUser({ commit }, data) {
      return ApiService.delete('api/client/user', data);
    }
  },
  getters: {

  }
};
