<template>
  <div class="modal-wrapper" id="modal-forget">
    <div class="modal">
      <div class="contents">
        <div class="modal-close" :title="$t('common.close')"></div>

        <div class="h1">{{ $t("modal.edit-profile.new-password") }}</div>
        <div class="h2">{{ $t("modal.edit-profile.enter-new-password") }}</div>
        <div class="form" id="form-forget">
          <form action="#" method="post" onsubmit="return false;">
            <div class="input input-password hidden">
              <input type="password" name="password" :placeholder="$t('modal.edit-profile.new-password')" maxlength="255" v-model="form.password">
              <i>{{ $t("modal.edit-profile.new-password") }}</i>
              <span class="tool"></span>
            </div>
            <div class="input input-password hidden">
              <input type="password" name="password2" :placeholder="$t('modal.edit-profile.repeat-new-password')" maxlength="255" v-model="form.password2">
              <i>{{ $t("modal.edit-profile.repeat-new-password") }}</i>
              <span class="tool"></span>
            </div>
            <button class="btn " disabled="disabled" @click="onChangePasswordClick">
              {{ $t("modal.edit-profile.change-password") }}
            </button>
          </form>
        </div>
        <a href="#modal-login" class="forget js-modal-link">{{ $t('modal.edit-profile.dont-change-password') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import modal from '../../assets/js/init/modal';
  import { eventBus } from '@/main.js';
  import { mapActions } from 'vuex';

  export default {
    name: 'ChangePasswordModal',
    data() {
      return {
        login: null,
        form: {
          password: null,
          password2: null,
          passwordLength: 5,
        }
      };
    },
    mounted() {
      eventBus.$on('onChangePassword', (data) => {
        this.login = data.login;
        modal.openModal('modal-forget');
      });
    },
    methods: {
      ...mapActions(['sendVerificationCodeForPasswordChanging']),
      onChangePasswordClick(event) {
        event.preventDefault();
        this.sendVerificationCodeForPasswordChanging(this.login)
          .then(() => {
            eventBus.$emit('onConfirmViaCode', {login: this.login, password: this.form.password, type: "changePassword"});
          }).catch(error => this.showToast(error.data.message, 'error'));
      }
    }
  };
</script>

<style scoped>

</style>
