<template>
  <div class="modal-wrapper" id="modal-choose-location">
    <div class="modal">
      <div class="contents">
        <div class="modal-close" :title="$t('common.close')"></div>

        <div class="h1">{{ $t('modal.location.add-location') }}</div>


          <div class="search">
            <GmapAutocomplete @place_changed="setPlace">
              <template v-slot:input="slotProps">
                <input
                  ref="input"
                  type="text"
                  name="location"
                  :placeholder="$t('modal.location.search-location')"
                  v-on:listeners="slotProps.listeners"
                  v-on:attrs="slotProps.attrs"
                  :select-first-on-enter="true"
                />
              </template>
            </GmapAutocomplete>
          </div>
          <div id="map-modal" v-show="type === 'single'">
            <GmapMap
              :center="{ lat: geo.latitude, lng: geo.longitude }"
              :zoom="15"
              map-type-id="terrain"
              style="width: 100%; height: 100%"
              ref="mapRef"
              :options="{
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
              }"
              @click="onMapClick"
            >
              <GmapMarker
                v-if="!!google"
                v-for="item in markers"
                :key="item.id"
                :position="{ lat: item.geo.latitude, lng: item.geo.longitude }"
                :draggable="false"
                :icon="{
                  strokeColor: '#FFFFFF',
                  strokeOpacity: 1,
                  strokeWeight: 3,
                  fillColor: '#00D9B2',
                  fillOpacity: 1,
                  path: google.maps.SymbolPath.CIRCLE,
                  scale: 15,
                  anchor: new google.maps.Point(0, 0),
                }"
              >
              </GmapMarker>
            </GmapMap>
          </div>

          <div id="map-modal" v-show="type === 'multiple'">
            <GmapMap
              :center="{ lat: geo.latitude, lng: geo.longitude }"
              :zoom="15"
              map-type-id="terrain"
              style="width: 100%; height: 100%"
              ref="mapRef"
              :options="{
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
              }"
              @click="onMapClick"
            >
              <GmapMarker
                v-if="!!google"
                v-for="(item, index) in markers"
                :key="item.id"
                :position="{ lat: item.geo.latitude, lng: item.geo.longitude }"
                :draggable="true"
                :icon="{
                  strokeColor: '#FFFFFF',
                  strokeOpacity: 1,
                  strokeWeight: 3,
                  fillColor: '#00D9B2',
                  fillOpacity: 1,
                  path: google.maps.SymbolPath.CIRCLE,
                  scale: 15,
                  anchor: new google.maps.Point(0, 0),
                }"
                @dragend="onDragAnDropMarker($event, index)"
                @rightclick="deleteMarker(index)"
              >
              </GmapMarker>
            </GmapMap>
          </div>

          <div class="btn-line">
            <button class="btn" @click="onApplyMarkersClick">{{ $t('common.save') }}</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import modal from "../../assets/js/init/modal";
import { gmapApi } from "gmap-vue";
import { eventBus } from "../../main";

export default {
  name: "LocationModal",
  data() {
    return {
      geo: {
        latitude: null,
        longitude: null,
      },
      markers: [],
      type: "single",
    };
  },
  computed: {
    google: gmapApi,
  },
  created() {
    this.setDefaultMapPosition();
    eventBus.$on("onChooseLocation", (geo) => {
      this.markers.splice(0, this.markers.length);
      if (geo && geo.latitude !== null && geo.longitude !== null) {
        this.geo.latitude = geo.latitude;
        this.geo.longitude = geo.longitude;
        this.markers.push({ geo: geo });
      }
      this.type = "single";
      modal.openModal("modal-choose-location");
    });
    eventBus.$on("onChooseLocations", (geo) => {
      this.markers.splice(0, this.markers.length);
      if (geo.length) {
        this.geo.latitude = geo[0].latitude;
        this.geo.longitude = geo[0].longitude;
      }
      for (let i = 0; i < geo.length; i++) {
        this.markers.push({
          id: i,
          geo: { ...geo[i] },
        });
      }
      this.type = "multiple";
      modal.openModal("modal-choose-location");
    });
  },
  methods: {
    setPlace(place) {
      console.log(place);
      if(!place.geometry) return;
      this.geo.latitude = place.geometry.location.lat();
      this.geo.longitude = place.geometry.location.lng();
    },
    onMapClick(event) {
      if (this.type === "single") {
        this.markers.splice(0, this.markers.length);
      }
      this.markers.push({
        id: 1 + Math.max(0, ...this.markers.map((n) => n.id)),
        geo: {
          latitude: event.latLng.lat(),
          longitude: event.latLng.lng(),
        },
      });
    },
    onDragAnDropMarker(event, index) {
      this.markers[index].geo.latitude = event.latLng.lat();
      this.markers[index].geo.longitude = event.latLng.lng();
    },
    setDefaultMapPosition() {
      const stored = localStorage.getItem("currentLocation");
      if (stored) {
        const location = JSON.parse(stored);
        this.geo.latitude = location.latitude;
        this.geo.longitude = location.longitude;
      } else {
        this.geo.latitude = 55.751244;
        this.geo.longitude = 37.618423;
      }
    },
    onApplyMarkersClick() {
      eventBus.$emit("applyMarker", this.markers);
      modal.hideModal();
    },
    deleteMarker(index) {
      this.markers.splice(index, 1);
    },
  },
};
</script>

<style scoped>
</style>
