import i18n from "../../i18n";

export default function expireDateFilter(value) {
 const absVal = Math.abs(new Date(value) - new Date());
 const absFt = formatMs(absVal);
 return absFt;
}

function formatMs(value) {
  // если показывать 2 единицы времени, оно может просто не уместиться
  // поработать над округлением
  const minutes = value / 60000; // в минутах
  if(minutes < 60) {
    return  Math.floor(minutes) + i18n.t('common.minute');
  }
  const hours = minutes / 60;
  if(hours < 24) {
    return  Math.floor(hours) + i18n.t('common.hour');
  }
  const days = hours / 24;
  if(days < 7) {
    return  Math.floor(days) + i18n.t('common.day');
  }
  if(days < 30) {
    const weeks = days / 7;
    return Math.floor(weeks) + i18n.t('common.week');
  }
  const months = days / 30;
  if(months < 12) {
    return  Math.floor(months) + i18n.t('common.month');
  }
  const years = months / 12;
  return  Math.floor(years) + i18n.t('common.year');
}
