<template>
  <div class="contents-feedback contents-inner" id="profile-sidebar-feedback">
    <a href="#" class="backlink" data-toggle="#profile-sidebar-main">{{ $t('common.back') }}</a>

    <div class="h1">{{ $t("sidebar.feedback.feedback") }}</div>

      <div class="input input-clearing">
        <input v-model="email" type="email" name="email" placeholder="E-mail" value="Jessica@gmail.com" maxlength="255">
        <i>E-mail</i>
        <span class="tool"></span>
      </div>
      <div class="input">
        <textarea v-model="text" name="text" :placeholder="$t('sidebar.feedback.message')" class="js-autoheight"></textarea>
        <i>{{ $t("sidebar.feedback.message") }}</i>
      </div>
      <button @click="onSendClicked" class="btn" :disabled="submitDisabled">{{ $t("sidebar.feedback.send") }}</button>
  </div>
</template>

<script>
  import ApiService from '@/assets/js/services/api.service';
  import {mapGetters} from "vuex";
  export default {
    name: 'SidebarFeedbackTab',
    data() {
      return {
        text: "",
        email: "",
        isSending: false,
        unsubscribe: null,
      }
    },
    created() {
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if(mutation.type == "setUser") {
          if(state.authModule.user.email) {
            this.email = state.authModule.user.email;
          }
        }
      });
    },
    computed: {
      submitDisabled() {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(this.email) || this.text.length == 0 || this.isSending;
      },
    },
    methods: {
      async onSendClicked() {
        const data = {
          text: this.text,
          email: this.email
        }
        this.isSending = true;
        const response = await ApiService.post('/api/client/feedback', data);
        this.isSending = false;
        if(response.status == 200) {
          this.showToast(this.$t('toast.message-sent'), "success");
        } else {
          this.showToast(this.$t('toast.error'), "error");
        }
      },
    },
  };
</script>

<style scoped>

</style>
