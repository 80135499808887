<template>
  <div class="modal-wrapper" id="modal-choose-duration">
    <div class="modal">
      <div class="contents">
        <div
          class="modal-close"
          :title="$t('common.close')"
          @click="onModalClose"
        ></div>
        <form action="#" method="post" id="form-choose-duration">
          <div class="calendar js-calendar"></div>
          <input type="hidden" name="date" />

          <div class="h2">{{ $t("modal.calendar.time") }}</div>
          <div class="time-holder2">
            <input
              type="text"
              name="hours1"
              value=""
              min="0" max="9"
              maxlength="1"
              autocomplete="off"
            />
            <input
              type="text"
              name="hours2"
              value=""
              min="0" max="9"
              maxlength="1"
              autocomplete="off"
            />
            <span class="colon">:</span>
            <input
              type="text"
              name="minutes1"
              value=""
              min="0" max="9"
              maxlength="1"
              autocomplete="off"
            />
            <input
              type="text"
              name="minutes2"
              value=""
              min="0" max="9"
              maxlength="1"
              autocomplete="off"
            />
          </div>

          <button
            class="btn"
            disabled="disabled"
            @click="onDateChose"
          >
            {{ $t("common.save") }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import calendar from "../../assets/js/init/calendar";
import modal from "../../assets/js/init/modal";
import { eventBus } from "@/main.js";

export default {
  name: "CalendarModal",
  data() {
    return {
      saveDisabled: true
    };
  },
  mounted() {
    calendar.init();
  },
  watch: {
    $route(to, from) {
      this.clearModal();
    }
  },
  methods: {
    onDateChose(event) {
      event.preventDefault();
      let $formChooseDuration = $("#form-choose-duration");
      let arr = $formChooseDuration
        .find('input[name="date"]')
        .val()
        .split("-");
      arr.push(
        $formChooseDuration.find('input[name="hours1"]').val() +
          $formChooseDuration.find('input[name="hours2"]').val()
      );
      arr.push(
        $formChooseDuration.find('input[name="minutes1"]').val() +
          $formChooseDuration.find('input[name="minutes2"]').val()
      );
      let selectedDate = new Date(arr[0], arr[1], arr[2], arr[3], arr[4]);
      eventBus.$emit("onDateChose", selectedDate);
      modal.hideModal();
    },
    onModalClose() {
      eventBus.$emit("onCalendarModalClose");
      modal.hideModal();
      this.clearModal();
    },
    clearModal() {
      setTimeout(() => {
        let $formChooseDuration = $("#form-choose-duration");
        $formChooseDuration.trigger("reset");
        let $cells = $formChooseDuration.find(".selected");
        if ($cells.length) {
          $cells.removeClass("selected");
        }
        $formChooseDuration.find("input").removeClass("filled");
      }, 500);
    }
  }
};
</script>

<style scoped></style>
