<template>
  <div class="modal-wrapper" id="modal-donate">
    <div class="modal">
      <div class="contents">
        <div class="modal-close" :title="$t('common.close')"></div>

        <div class="h1">{{ $t("modal.donate.enter-amount") }}</div>

        <div class="input">
          <input type="text" v-model="sum" name="sum" :placeholder="$t('modal.donate.amount')">
          <i>{{$t("modal.donate.amount")}}</i>
        </div>

        <ul class="templates fadeout js-fadeout" data-mobile-only="true" data-max-width="1000">
          <li v-for="s in sums" :data-val="s" @click="sum = s">{{s}}</li>
        </ul>

        <div class="cashback" v-show="points < 0"><span>{{ $t('modal.donate.wait') + pointsDots}}</span></div>
        <div class="cashback" v-show="points >= 0">
          <span :class="{'act' : sum > 0}">{{ points}}</span>
          {{ $t("modal.donate.you-will-get") }}
        </div>

        <button class="btn" :disabled="sum <= 0" @click="onCheckoutClick">{{ $t('modal.donate.next') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
  import {eventBus} from '@/main.js';
  import modal from '../../assets/js/init/modal';
  import {mapActions} from 'vuex';

  export default {
    name: 'DonateModal',
    data() {
      return {
        sums: [50, 100, 200, 500, 1000],
        sum: 0,
        points: 0,
        pointsDots: '',
        dotsInterval: null,
      }
    },
    watch: {
      sum() {
        this.pointsDots = '';
        this.points = -1;

        if(this.dotsInterval === null) {
          this.dotsInterval = setInterval(() => {
            if(this.pointsDots.length < 5) {
              this.pointsDots += '.';
            } else {
              this.pointsDots = '';
            }
          }, 500)
        }

        this.calculateDonatePoints({id: this.$route.params.id, value: this.sum}).then((result) => {
          this.points = result.data.bonusAmount;
        })
      }
    },
    created() {
      eventBus.$on('openDonatesModal', () => {
        this.sum = 0;
        this.points = 0;
        modal.openModal('modal-donate')
      });
    },
    beforeDestroy: function () {
      if (this.dotsInterval !== null) {
        clearInterval(this.dotsInterval);
      }
    },
    methods: {
      ...mapActions(['calculateDonatePoints', 'pay']),
      onCheckoutClick() {
        this.pay({id: this.$route.params.id, amount: this.sum}).then((result) => {
          this.$logEvent(this.$analytics, 'purchase');
          window.open(result.data.confirmationUrl);
        }).catch(() => {
          this.showToast(this.$t('toast.payment-error'), 'error');
        });
        this.showToast(this.$t('toast.please-wait'), 'info');
        modal.hideModal();
      }
    }
  };
</script>

<style scoped>

</style>
