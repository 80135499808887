<template>
  <div class="modal-wrapper" id="modal-upload-photo">
    <div class="modal">
      <div class="contents">
        <div class="modal-close" :title="$t('common.close')"></div>

        <div class="h1">{{ $t('modal.photo.upload') }}</div>

        <div class="field" id="upload-file-field">
          <form ref="fileInput" enctype="multipart/form-data">
            <input type="file" name="photo" id="upload-file-input" accept="image/*" @change="uploadPhoto">
          </form>
          <button class="btn js-upload-file">{{ $t('modal.photo.choose-on-pc') }}</button>
          <span class="js-cancel cancel">{{ $t('common.cancel') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import modal from '../../assets/js/init/modal';
  import {eventBus} from '@/main.js';

  export default {
    name: 'UploadPhotoModal',
    mounted() {
      $('#upload-file-field .js-upload-file')
        .click(function (e) {
          e.preventDefault();
          $('#upload-file-input')
            .click();
        });
    },
    methods: {
      ...mapActions(['uploadFile']),
      uploadPhoto(event) {
        let file = event.target.files[0];
        this.$refs.fileInput.reset();
        if (!file) {
          return;
        }

        modal.hideModal();
        let formData = new FormData();
        formData.append('file', file);
        this.uploadFile(formData)
          .then((response) => {
            eventBus.$emit('imageForMessageLoaded', response.data.id);
          })
          .catch(() => {
            this.newLogo = null;
          });

      },
    }
  };
</script>

<style scoped>

</style>
