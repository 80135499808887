<template>
  <div>
    <div id="layout">
      <header :style="{'background-color' : getHeaderColor()}">
        <auth-header v-if="isAuthenticated"/>
        <default-header v-else/>
      </header>
        <router-view :key="$route.fullPath"/>
      <footer v-show="!(($route.name === 'chat' || $route.name === 'newChat') && isMobile)">
        <v-footer/>
      </footer>
    </div>

    <modal-container/>


    <profile-side-bar/>
  </div>
</template>

<script>
  import VFooter from './VFooter.vue';
  import DefaultHeader from './DefaultHeader.vue';
  import AuthHeader from './AuthHeader.vue';
  import ProfileSideBar from '../components/layouts/ProfileSideBar.vue';
  import { getLocation } from "../assets/js/geoUtils.js";
  import { mapGetters, mapMutations } from 'vuex';
  import ModalContainer from '../components/ModalContainer.vue';
  import settings from '../assets/js/settings';

  export default {
    name: 'VLayout',
    components: {
      ModalContainer,
      ProfileSideBar,
      DefaultHeader,
      AuthHeader,
      VFooter
    },
    data() {
      return {
        isMobile: settings.__isMobile
      }
    },
    computed: {
      ...mapGetters(['isAuthenticated', 'user']),
    },
    watch:{
      '$route' (to, from){
        this.$logEvent(this.$analytics, 'goto_page');
      }
    },
    created() {
      this.detectGeolocation();
    },
    methods: {
      ...mapMutations(['setUserGeo']),
      detectGeolocation() {
        let that = this;
        getLocation(location => {
          that.setUserGeo(location);
        });
      },
      getHeaderColor() {
        if (this.user != null && this.user.theme != null && this.user.theme.primaryColor != null && this.user.theme.primaryColor != '') {
          return this.user.theme.primaryColor;
        }
        else {
          return '#fff';
        }
      }
    }
  };
</script>

<style scoped>

</style>
