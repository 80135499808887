var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-wrapper",attrs:{"id":"modal-choose-location"}},[_c('div',{staticClass:"modal"},[_c('div',{staticClass:"contents"},[_c('div',{staticClass:"modal-close",attrs:{"title":_vm.$t('common.close')}}),_c('div',{staticClass:"h1"},[_vm._v(_vm._s(_vm.$t('modal.location.add-location')))]),_c('div',{staticClass:"search"},[_c('GmapAutocomplete',{on:{"place_changed":_vm.setPlace},scopedSlots:_vm._u([{key:"input",fn:function(slotProps){return [_c('input',{ref:"input",attrs:{"type":"text","name":"location","placeholder":_vm.$t('modal.location.search-location'),"select-first-on-enter":true},on:{"listeners":slotProps.listeners,"attrs":slotProps.attrs}})]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'single'),expression:"type === 'single'"}],attrs:{"id":"map-modal"}},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":{ lat: _vm.geo.latitude, lng: _vm.geo.longitude },"zoom":15,"map-type-id":"terrain","options":{
              zoomControl: true,
              mapTypeControl: true,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
              disableDefaultUi: false,
            }},on:{"click":_vm.onMapClick}},_vm._l((_vm.markers),function(item){return (!!_vm.google)?_c('GmapMarker',{key:item.id,attrs:{"position":{ lat: item.geo.latitude, lng: item.geo.longitude },"draggable":false,"icon":{
                strokeColor: '#FFFFFF',
                strokeOpacity: 1,
                strokeWeight: 3,
                fillColor: '#00D9B2',
                fillOpacity: 1,
                path: _vm.google.maps.SymbolPath.CIRCLE,
                scale: 15,
                anchor: new _vm.google.maps.Point(0, 0),
              }}}):_vm._e()}),1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'multiple'),expression:"type === 'multiple'"}],attrs:{"id":"map-modal"}},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":{ lat: _vm.geo.latitude, lng: _vm.geo.longitude },"zoom":15,"map-type-id":"terrain","options":{
              zoomControl: true,
              mapTypeControl: true,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
              disableDefaultUi: false,
            }},on:{"click":_vm.onMapClick}},_vm._l((_vm.markers),function(item,index){return (!!_vm.google)?_c('GmapMarker',{key:item.id,attrs:{"position":{ lat: item.geo.latitude, lng: item.geo.longitude },"draggable":true,"icon":{
                strokeColor: '#FFFFFF',
                strokeOpacity: 1,
                strokeWeight: 3,
                fillColor: '#00D9B2',
                fillOpacity: 1,
                path: _vm.google.maps.SymbolPath.CIRCLE,
                scale: 15,
                anchor: new _vm.google.maps.Point(0, 0),
              }},on:{"dragend":function($event){return _vm.onDragAnDropMarker($event, index)},"rightclick":function($event){return _vm.deleteMarker(index)}}}):_vm._e()}),1)],1),_c('div',{staticClass:"btn-line"},[_c('button',{staticClass:"btn",on:{"click":_vm.onApplyMarkersClick}},[_vm._v(_vm._s(_vm.$t('common.save')))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }