<template>
  <div class="modal-wrapper modal-forget" id="modal-delete-user">
    <div class="modal">
      <div class="contents">
        <modal-close-button/>

        <div class="h1">{{ $t("modal.confirm-delete-user.are-you-confirm") }}</div>
        <div class="form form-forget">
          <form action="#" method="post" onsubmit="return false;">
            <button class="btn" @click="onCancelClicked">{{ $t('modal.confirm-delete-user.dont-confirm') }}</button>
          </form>
        </div>
        <a class="forget" @click="onDeleteClicked">{{ $t('modal.confirm-delete-user.confirm') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import {eventBus} from '../../main';
  import modal from '../../assets/js/init/modal';
  import {mapActions} from "vuex";
  import ModalCloseButton from '../layouts/ModalCloseButton';
  import settings from "../../assets/js/settings";

  export default {
    name: 'ApproveModal',
    components: { ModalCloseButton },
    methods: {
      ...mapActions(['deleteUser', 'logout']),
      onDeleteClicked() {
        this.deleteUser().then(() => {
          this.logout(this.form)
                  .then(resp => {
                    this.$router.go();
                  })
                  .catch(err => console.warn(err));
        }).catch(error => {
          this.showToast(error.data.message, 'error');
        });
        modal.hideModalWithoutFadeout();
      },
      onCancelClicked() {
        modal.hideModalWithoutFadeout();
        setTimeout(() => {
          this.password = null;
          this.password2 = null;
        }, settings.__animationSpeed);
      },
    }
  };
</script>

<style scoped>

</style>
