import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '../public/css/style.css';

Vue.config.productionTip = false;
window.appSettings = require('./assets/js/settings.js').default;

export const eventBus = new Vue();

import ApiService from './assets/js/services/api.service';
ApiService.init();

// filters
import expireDateFilter from './assets/filters/expireDateFilter';
Vue.filter('expireDate', expireDateFilter);
import chatTimeFilter from './assets/filters/chatTimeFilter';
Vue.filter('chatTimeFilter', chatTimeFilter);

// mixins
/*import titleMixin from './components/mixins/titleMixin'
Vue.mixin(titleMixin)*/
import toastMixin from './components/mixins/toastMixin';
Vue.mixin(toastMixin);

import * as VueGoogleMaps from "gmap-vue";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD94VydEISej3Y6hk7T0JmpxlmKITiEIDc",
    libraries: "places"
    // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
});

import VueYandexMetrika from "vue-yandex-metrika";
Vue.use(VueYandexMetrika, {
    id: 88306057,
    router: router,
    env: "production",
    debug: "false",
    options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        trackHash: true,
        ecommerce: "dataLayer"
    }
});

import 'vue-progress-path/dist/vue-progress-path.css'
import VueProgress from 'vue-progress-path'

// loader icon
Vue.use(VueProgress, {
  defaultShape: 'circle',
});

// toast
import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

// filters
import dateFilter from './assets/filters/date.filter';
Vue.filter('date', dateFilter);

import * as firebase from "firebase/app";
import "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import i18n from './i18n'

const firebaseConfig = {
  apiKey: "AIzaSyDxiZ2yubcQ6DP2xzpbNL3jb1PYy8lH4u8",
  authDomain: "karmitt-r2d2.firebaseapp.com",
  databaseURL: "https://karmitt-r2d2.firebaseio.com",
  projectId: "karmitt-r2d2",
  storageBucket: "karmitt-r2d2.appspot.com",
  messagingSenderId: "758726260356",
  appId: "1:758726260356:web:fa805563aba7d6d4d674d1",
  measurementId: "G-S4BRGMHTQ3"
};

firebase.initializeApp(firebaseConfig);
Vue.config.productionTip = false;
Vue.prototype.$logEvent = logEvent;
Vue.prototype.$analytics = getAnalytics();

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app');
