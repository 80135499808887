<template>
  <div id="mn-holder">
    <ul id="mn-top">
      <li class="items" :class="{'act': $route.name === 'objects'}" >
        <a href="" @click.prevent="goToMain">
          <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0003 0.166992C16.9803 0.166992 21.8337 5.02033 21.8337 11.0003C21.8337 16.9912 16.9803 21.8337 11.0003 21.8337C5.00949 21.8337 0.166992 16.9912 0.166992 11.0003C0.166992 5.02033 5.00949 0.166992 11.0003 0.166992ZM15.1712 7.43616C15.2903 7.05699 14.9437 6.69949 14.5645 6.81866L9.01783 8.55199C8.79033 8.62783 8.60616 8.80116 8.54116 9.02866L6.80783 14.5862C6.68866 14.9545 7.04616 15.312 7.41449 15.1928L12.9395 13.4595C13.167 13.3945 13.3512 13.2103 13.4162 12.9828L15.1712 7.43616Z"/>
          </svg>
          {{ $t("header.goods") }}
        </a>
      </li>
      <li class="deals" :class="{'act': $route.name === 'deals'}" >
        <a href="" @click.prevent="goToDeals">
          <svg viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.6105 15.8947C23.4789 14.2175 23.7193 12.2852 23.2883 10.4464C22.8573 8.60757 21.7832 6.98337 20.26 5.86685C18.7367 4.75033 16.8645 4.21504 14.9812 4.35755C13.0979 4.50006 11.3276 5.311 9.98972 6.64403C8.6518 7.97706 7.83439 9.7444 7.68498 11.6271C7.53558 13.5099 8.06402 15.384 9.17495 16.9114C10.2859 18.4387 11.9062 19.5187 13.7434 19.9565C15.5806 20.3942 17.5138 20.1609 19.1941 19.2986L22.3068 19.8893C22.4193 19.9098 22.5351 19.903 22.6443 19.8694C22.7536 19.8358 22.8532 19.7765 22.9348 19.6963C23.0164 19.6162 23.0775 19.5177 23.113 19.409C23.1485 19.3003 23.1573 19.1847 23.1388 19.0718L22.6105 15.8947Z"/>
            <path d="M14.6095 2.95826C13.2963 1.57402 11.5275 0.71041 9.62834 0.526248C7.72921 0.342087 5.82744 0.849755 4.27275 1.95589C2.71807 3.06203 1.61499 4.69226 1.16637 6.54681C0.717746 8.40135 0.953748 10.3555 1.83097 12.0499L1.29953 15.2261C1.28101 15.3389 1.28988 15.4545 1.32538 15.5632C1.36089 15.6719 1.42199 15.7705 1.50354 15.8506C1.5851 15.9307 1.68472 15.9901 1.79401 16.0236C1.9033 16.0572 2.01906 16.064 2.13153 16.0435L5.24425 15.4528C5.81916 15.7469 6.42794 15.9693 7.05697 16.1153C6.44812 14.7654 6.16823 13.2904 6.2403 11.8113C6.31237 10.3322 6.73434 8.89137 7.47155 7.60709C8.20875 6.32282 9.24016 5.23178 10.481 4.42363C11.7219 3.61547 13.1368 3.11326 14.6095 2.95826Z"/>
          </svg>
          {{ $t("header.deals") }}
        </a>
      </li>
    </ul>
    <div class="close" ref="close"></div>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex';
  import burger from '../../assets/js/init/burger';
  import settings from '../../assets/js/settings';

  export default {
    name: 'HeaderButtons',
    mounted() {
      burger.init();
    },
    methods: {
      ...mapMutations(["clearObjects", "clearDeals"]),
      goToMain() {
        if(settings.__isMobile) {
          this.$refs.close.click();
        }
        if(this.$route.name !== 'objects'){
          this.clearObjects();
          this.$router.push({ name: 'objects' })
        }

      },
      goToDeals() {
        if(settings.__isMobile) {
          $('#mn-holder .close').click();
        }
        if(this.$route.name !== 'deals'){
          this.clearDeals();
          this.$router.push({ name: 'deals' })
        }
      }
    }
  };
</script>

<style scoped>

</style>
