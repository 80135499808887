<template>
  <div class="modal-wrapper" id="modal-complain">
    <div class="modal">
      <div class="contents">
        <div class="modal-close" :title="$t('common.close')"></div>

        <div class="h1">{{ $t("common.report") }}</div>
        <div class="text">
          <p>{{ $t("modal.report.choose-reason") }}</p>
        </div>
        <div class="form" id="form-complain">
          <form action="#" method="post" onsubmit="return false;">
            <fieldset>
              <label v-for="(item, index) in complaints" :key="item.id" :ref="'complaintsRef' + index">
                <input type="checkbox" :name="'cause' + index" @click="addRemoveCategory(item.id)">
                <span class="header">{{item.name}}</span>
                <span class="text">{{item.description}}</span>
              </label>
            </fieldset>

            <button class="btn" @click="onSendComplaintClicked" :disabled="selectedCategories.length === 0">{{ $t("modal.report.send") }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { eventBus } from '../../main';
  import modal from '../../assets/js/init/modal';

  export default {
    name: 'ComplainModal',
    data() {
      return {
        goodsId: null,
        chatId: null,
        complaints: [],
        selectedCategories: [],
      };
    },
    created() {
      this.loadComplaintCategories()
        .then((result) => {
          result.data.forEach((item) => {
            this.complaints.push({
              ...item,
              value: false
            });
          });
        });
      eventBus.$on('onOpenComplaint', (data) => {
        this.selectedCategories.splice(0, this.selectedCategories.length);
        $('input[type=checkbox]').prop('checked', false);

        this.goodsId = data.goodsId;
        this.chatId = data.chatId;

        modal.openModal('modal-complain')
      })
    },
    methods: {
      ...mapActions(['loadComplaintCategories', 'sendComplaints']),
      addRemoveCategory(value) {
        let index = this.selectedCategories.indexOf(value);
        if(index !== -1) {
          this.selectedCategories.splice(index, 1);
        } else {
          this.selectedCategories.push(value);
        }
      },
      onSendComplaintClicked() {
        let body = {
          goodsId: this.goodsId,
          chatId: this.$route.params.id,
          categories: this.selectedCategories
        };
        this.sendComplaints(body)
          .then(() => this.showToast(this.$t('toast.report-accepted')))
          .catch(error => this.showToast(error.response.data.message, 'error'));
        modal.hideModal();
        this.selectedCategories.splice(0, this.selectedCategories.length);
      },
    }
  };
</script>

<style scoped>

</style>
