<template>
  <aside class="col">
    <nav id="mn-side">
      <ul
        class="js-fadeout fadeout"
        data-mobile-only="true"
        data-max-width="1000"
      >
        <li
          :class="{ act: favorite }"
          class="selected"
          @click="selectCategory('favorite')"
        >
          <a
            >{{ $t("common.favorite") }}
            <span>
              <img
                src="../../public/images/ico_star.svg"
                alt="favorite'"
                title="favorite"
                class="svg"
                :class="{ hollow: favorite }"
              />
            </span>
          </a>
        </li>
        <li
          :class="{ act: newest }"
          class="selected"
          @click="selectCategory('newest')"
        >
          <a
            >{{ $t("common.new") }}
            <span>
              <img
                src="../../public/images/ico_new.png"
                alt="newest"
                title="newest"
                class="svg"
                :class="{ hollow: newest }"
              />
            </span>
          </a>
        </li>

        <li
          v-for="item in goodsCategories"
          :key="item.id"
          class="selected"
          :class="{ act: selectedCategory.indexOf(item.id) != -1 }"
          @click="selectCategory(item.id)"
        >
          <a>
            {{ item.name }}
            <span>
              <img
                :src="imageSource(item.resourceId)"
                :alt="item.name"
                :title="item.name"
                class="svg"
                :class="{ hollow: selectedCategory.indexOf(item.id) != -1 }"
              />
            </span>
          </a>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import fadeout from "../assets/js/init/fadeout";

export default {
  name: "CategoryList",
  props: ["category"],
  data() {
    return {};
  },
  async created() {
    if (this.$route.params.category) {
      this.changeCategory({
        ids: [this.$route.params.category],
        favorite: false,
        newest: this.newest
      });
    }
    await this.loadGoodsCategories();
    fadeout.fadeoutInit();
  },
  computed: {
    ...mapGetters({
      goodsCategories: "goodsCategories",
      selectedCategory: "selectedCategory",
      favorite: "favorite",
      newest: "newest"
    })
  },
  methods: {
    ...mapActions(["loadGoodsCategories"]),
    ...mapMutations([
      "changeCategory",
      "addCategory",
      "clearCategories",
      "removeCategory",
      "setFavorite",
      "setNewest"
    ]),
    imageSource: function(id) {
      let src = appSettings.baseUrl + "api/client/resource/" + id;

      return src;
    },
    selectCategory(id) {
      if (id === "favorite") {
        this.setFavorite(!this.favorite);
      } else if (id == "newest") {
        this.setNewest(!this.newest);
      } else {
        let index = this.selectedCategory.indexOf(id);
        if (index !== -1) {
          this.removeCategory(index);
        } else {
          this.addCategory(id);
        }
      }

      this.$emit(
        "category-selected",
        this.selectedCategory,
        this.favorite,
        this.newest
      );
    }
  }
};
</script>

// todo обратить внимание на переход цвета
<style scoped>
.hollow {
  filter: brightness(0) invert(1);
}
</style>
