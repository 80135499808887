import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

async function getLocation(successCallback, errorCallback) {
    const resp = await Vue.axios.get('https://pro.ip-api.com/json?key=S0YZqlKecVmL4WU');
    const networkCoords = {
        latitude: resp.data.lat,
        longitude: resp.data.lon
    };

    if("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        pos => {
          const newCoords = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          };
          successCallback(newCoords);
        }, successCallback(networkCoords));
    }
  else {
        successCallback(networkCoords);
    }
}
export { getLocation }
