<template>
  <div class="contents-edit contents-inner" id="profile-sidebar-edit">
    <a href="#" class="backlink" data-toggle="#profile-sidebar-main">{{ $t('common.back') }}</a>

    <div class="h1">{{ $t('sidebar.profile-edit.personal-data') }}</div>

    <form action="#" method="post" id="form-profile-edit">
      <div class="input input-predefined">
        <input type="text" name="name" :placeholder="$t('sidebar.profile-edit.name')" maxlength="255" disabled="disabled" :value="name" ref="userName">
        <i>{{ $t('sidebar.profile-edit.name') }}</i>
        <span class="tool" @click="onNameEditClicked"></span>
      </div>
      <div class="input input-predefined">
        <input type="text" name="company" :placeholder="$t('sidebar.profile-edit.company')" maxlength="255" disabled="disabled" :value="company" ref="company">
        <i>{{ $t('sidebar.profile-edit.company') }}</i>
        <span class="tool" @click="onCompanyEditClicked"></span>
      </div>
      <div class="input input-predefined">
        <input type="email" name="email" placeholder="Email" maxlength="255" disabled="disabled" :value="email" ref="userEmail">
        <i>Email</i>
        <span class="tool" @click="onEmailEditClicked"></span>
      </div>
      <div class="input input-predefined">
        <input type="password" name="password" :placeholder="$t('sidebar.profile-edit.password')" maxlength="255" disabled="disabled" value="*****">
        <i>{{ $t('sidebar.profile-edit.password') }}</i>
        <span class="tool" @click="onPasswordEditClicked"></span>
      </div>
      <!--<button class="btn" disabled="disabled" @click="onSaveClicked">Сохранить</button>-->
    </form>
    <a href="#" class="delete-account" @click="deleteMe">{{ $t('sidebar.main.delete-account') }}</a>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { eventBus } from '../../main';
  import modal from "../../assets/js/init/modal";

  export default {
    name: 'SidebarProfileEditTab',
    data() {
      return {
        nameValue: null,
        companyValue: null,
        emailValue: null
      };
    },
    computed: {
      ...mapGetters(['user']),
        name: function () {
            if (this.nameValue == null) {
                return this.$store.getters.user.name;
            }
            else {
                return this.nameValue;
            }
        },
        email: function () {
            if (this.emailValue == null) {
                return this.$store.getters.user.email;
            }
            else {
                return this.emailValue;
            }
        },
        company: function () {
            if (this.companyValue == null) {
                return this.$store.getters.user.company;
            }
            else {
                return this.companyValue;
            }
        }
    },
    created() {
      let that = this;

      eventBus.$on('profile-changed', (p) => {
          that.nameValue = p.name;
          that.companyValue = p.company;
          that.emailValue = p.email;
      });
    },
    mounted() {
    },
    methods: {
      ...mapActions(['saveUserData']),
      onPasswordEditClicked() {
        eventBus.$emit('passwordEdit');
      },
      onEmailEditClicked() {
        eventBus.$emit('emailEdit');
      },
      onNameEditClicked() {
        eventBus.$emit('nameEdit');
      },
      onCompanyEditClicked() {
        eventBus.$emit('companyEdit');
      },
      deleteMe() {
        modal.openModal('modal-delete-user');
      }
    }
  };
</script>

<style scoped>

</style>
