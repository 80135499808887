<template>
  <div class="modal-wrapper modal-login" id="modal-change-name">
    <div class="modal">
      <div class="contents">
        <modal-close-button/>

        <div class="h1">{{ $t("modal.edit-profile.change-name") }}</div>
        <div class="h2">{{ $t("modal.edit-profile.enter-new-name") }}</div>
        <div class="form form-start">
          <form action="#" method="post" onsubmit="return false;">
            <div class="input">
              <input type="text" name="email" :placeholder="$t('modal.edit-profile.name')" maxlength="255" v-model="username">
              <i>{{$t('modal.edit-profile.name')}}</i>
            </div>
            <button class="btn" :disabled="username.length < 2" @click="onChangeClicked">{{ $t('common.change') }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { eventBus } from '../../../main';
  import modal from '../../../assets/js/init/modal';
  import { mapActions } from 'vuex';
  import ModalCloseButton from '../../layouts/ModalCloseButton';

  export default {
    name: 'ProfileChangeNameModal',
    components: { ModalCloseButton },
    data() {
      return {
        username: '',
      }
    },
    created() {
      eventBus.$on('nameEdit', () => {
        modal.openModal('modal-change-name')
      })
    },
    methods: {
      ...mapActions(['saveUserData']),
      onChangeClicked() {
        this.saveUserData({name: this.username}).then((result) => {
          this.showToast(this.$t('toast.name-changed'));
          modal.hideModalWithoutFadeout();
          eventBus.$emit('profile-changed', result.data);
        }).catch(error => {
          this.showToast(error.response.data.message, 'error');
        });
      }
    }
  };
</script>

<style scoped>

</style>
