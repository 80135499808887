<template>
  <div class="modal-wrapper" id="modal-sale" ref="qrContainer" always-scrollable="true">
    <div class="modal">
      <div class="contents">
        <div class="modal-close" :title="$t('common.close')" @click="onCloseClicked"></div>

        <div class="h1">{{ $t('modal.sale.view') }}</div>

        <div class="sale">
          <div class="ico discount" v-if="type === 'sale'">{{sale}}%</div>
          <div class="title">{{title}}</div>
          <div class="karmitts">{{cost}}</div>
        </div>

        <div class="qr">
          <h2 v-show="loaded" style="text-align: center;">{{code}}</h2>
          <loading-progress
              v-show="!loaded"
              :progress="0"
              :indeterminate="true"
              :counter-clockwise="false"
              :hide-background="false"
              shape="circle"
              :size="loadingIconSize"
          />
          <div v-show="loaded" ref="qrBlock" style="text-align: center;"></div>
          <p v-if="description != null">{{description}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { eventBus } from '../../main';
  import modal from '../../assets/js/init/modal';
  import {mapActions} from 'vuex';
  import settings from '../../assets/js/settings';

  export default {
    name: 'SaleModal',
    data() {
      return {
        title: '',
        type: '',
        sale: 0,
        cost: 0,
        loadingIconSize: 100,
        loaded: false,
        code: '',
        qrCode: null,
        description: null,
      }
    },
    created() {
      eventBus.$on('showQrModal', (data) => {
        this.title = data.title;
        this.type = data.type;
        this.sale = data.sale;
        this.cost = data.cost;
        this.description = data.description;
        this.loadPayload({id: data.id}).then((result) => {
          this.code = result.data.payload;

          this.updateQrCode();

          this.loaded = true;
        });

        modal.openModal('modal-sale', true);
      })
    },
    mounted() {
      this.qrCodeInit();
    },
    methods: {
      ...mapActions(['loadPayload']),
      qrCodeInit() {
        this.qrCode = new VanillaQR({
          url: "karmitt",

          // width/height in pixels
          width: 250,
          height: 250,

          // colors
          colorLight: "#FFFFFF",
          colorDark: "#000000",

          // output as table or canvas
          toTable: false,

          // Ecc correction level 1-4
          ecclevel: 1,

          // Use a border or not
          noBorder: true,
        });
        let imageElement = this.qrCode.toImage("png");
        this.$refs.qrBlock.appendChild(imageElement);
      },
      updateQrCode() {
        this.qrCode.url = this.code;
        this.qrCode.init();
        this.$refs.qrBlock.removeChild(this.$refs.qrBlock.lastChild);
        let imageElement = this.qrCode.toImage("png");
        this.$refs.qrBlock.appendChild(imageElement);
      },
      onCloseClicked() {
        setTimeout(() => {
          this.code = 'Karmitt';
          this.loaded = false;
          this.updateQrCode();
        }, settings.__animationSpeed)

      }
    }
  };
</script>

<style scoped>

</style>
