<template>
  <div class="holder wrap">
    <a href="/" id="logo-footer"
      ><img
        src="../../public/images/logo.svg"
        :alt="$t('footer.for-free')"
        :title="$t('footer.for-free')"
    /></a>

    <div id="copyright">&copy; 2023 Karmitt</div>
    <div id="mn-conditions">
      <a :href="termsAndConditionLink">{{ $t("footer.conditions") }}</a>
      <a :href="promoLink">{{ $t("footer.for-partners") }}</a>
    </div>

    <div id="social-media">
      {{ $t("footer.we-are-in-social-networks") }}
      <ul>
        <li>
          <a href="https://vk.com/karmittapp" target="_blank"
            ><img
              src="../../public/images/ico_ss_vk.svg"
              :alt="$t('footer.vk')"
              :title="$t('footer.vk')"
          /></a>
        </li>
        <li>
          <a href="https://t.me/karmittapp" target="_blank"
          ><img
            src="../../public/images/ico_ss_tg.svg"
            alt="Telegram"
            title="Telegram"
          /></a>
        </li>
        <li v-if="false">
          <a href="https://www.facebook.com/Karmitt-102174451248328/"
            ><img
              src="../../public/images/ico_ss_fb.svg"
              alt="Facebook"
              title="Facebook"
          /></a>
        </li>
        <li v-if="false">
          <a href="https://www.instagram.com/karmittapp/"
            ><img
              src="../../public/images/ico_ss_in.svg"
              alt="Instagram"
              title="Instagram"
          /></a>
        </li>
      </ul>
    </div>
    <div id="mobile-app">
      {{ $t("footer.mobile-app") }}
      <ul>
        <li>
          <a href="https://apps.apple.com/ru/app/karmitt/id1510297787"
            ><img
              src="../../public/images/btn_app_as.svg"
              :alt="$t('footer.app-store')"
              :title="$t('footer.app-store')"
          /></a>
        </li>
        <li>
          <a
            href="https://play.google.com/store/apps/details?id=com.karmitt.mobile&hl=ru&gl=US"
            ><img
              src="../../public/images/btn_app_gp.svg"
              :alt="$t('footer.google-play')"
              :title="$t('footer.google-play')"
          /></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ApiService from "@/assets/js/services/api.service";

export default {
  name: "VFooter",
  data() {
    return {
      promoLink: "https://promo.karmitt.com/partners",
      termsAndConditionLink: ""
    };
  },
  async created() {
    const links = await ApiService.get("/api/client/links", null);
    if (!links || !links.data) return;
    this.termsAndConditionLink = links.data.termsAndConditions;
  }
};
</script>

<style scoped></style>
