<template>
  <div class="modal-wrapper" id="modal-booking">
    <div class="modal">
      <div class="contents">
        <div class="modal-close" :title="$t('common.close')"></div>

        <div class="h1">{{ $t("modal.booking-deal.are-you-confirm") }}</div>

        <div class="btn-line">
          <button class="btn cancel js-cancel">{{ $t("modal.booking-deal.dont-confirm") }}</button>
          <button class="btn" @click="onBookingClicked">{{ $t("modal.booking-deal.confirm") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {eventBus} from '../../main';
  import modal from '../../assets/js/init/modal';

  export default {
    name: 'BookingModal',
    methods: {
      onBookingClicked() {
        eventBus.$emit('onBookingDeal');
        modal.hideModal();
      }
    }
  };
</script>

<style scoped>

</style>
