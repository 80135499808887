<template>
  <div class="modal-wrapper" id="modal-end-chat">
    <div class="modal">
      <div class="contents">
        <div class="modal-close" :title="$t('common.close')"></div>

        <div class="h1">{{ $t('modal.end-chat.are-you-sure') }}</div>

        <div class="btn-line">
          <button class="btn cancel js-cancel">{{ $t('modal.end-chat.dont') }}</button>
          <button class="btn" @click="onEndChatClicked">{{ $t('modal.end-chat.do') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { eventBus } from '../../main';
  import modal from '../../assets/js/init/modal';

  export default {
    name: 'EndChatModal',
    methods: {
      onEndChatClicked() {
        eventBus.$emit('onEndChat');
      }
    }
  };
</script>

<style scoped>

</style>
