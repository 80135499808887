import ApiService from '@/assets/js/services/api.service';

export default {
  state: {},
  mutations: {},
  actions: {
    getPartnerById({ commit, dispatch }, params) {
      return ApiService.get('api/client/partners/' + params.id);
    },
    savePartner({ commit, dispatch }, body) {
      return ApiService.post('api/client/partners', body);
    },
    editPartner({ commit, dispatch }, body) {
      return ApiService.put('api/client/partners/' + body.id, body);
    },
    loadPartnerCategories({ commit, dispatch }, params) {
      return ApiService.get('api/client/partners/categories');
    },
  },
  getters: {}
};
