<template>
  <div class="modal-wrapper" id="modal-confirmation-code">
    <div class="modal">
      <div class="contents">
        <div class="modal-back" :title="$t('common.back')" @click="gotoLoginForm"></div>
        <div class="modal-close" :title="$t('common.close')"></div>

        <div class="h1">{{ $t("modal.confirm.confirm") }}</div>
        <div class="h2">{{ $t("modal.confirm.enter-code") }} {{ login }}</div>
        <div class="form" id="form-confirmation-code">
          <form action="#" method="post">
            <div class="code-holder">
              <input type="number" name="digit1" :value="codeNumbers.n1" min="0" max="9" maxlength="1"
                     autocomplete="off" ref="code1" @input="setN1" :class="{'err': error}">
              <input type="number" name="digit2" :value="codeNumbers.n2" min="0" max="9" maxlength="1"
                     autocomplete="off" ref="code2" @input="setN2" :class="{'err': error}">
              <input type="number" name="digit3" :value="codeNumbers.n3" min="0" max="9" maxlength="1"
                     autocomplete="off" ref="code3" @input="setN3" :class="{'err': error}">
              <input type="number" name="digit4" :value="codeNumbers.n4" min="0" max="9" maxlength="1"
                     autocomplete="off" ref="code4" @input="setN4" :class="{'err': error}">
            </div>
            <div class="message error" v-if="error">{{ $t("modal.confirm.wrong-code") }}</div>
            <a class="repeat" @click="resendCode">{{ $t("modal.confirm.resend") }}</a>
          </form>
        </div>
        <div class="note">{{ $t("modal.confirm.check-spam") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { eventBus } from '@/main.js';
  import confirmRegistrationModal from '../../assets/js/init/confirmRegistrationModal';
  import modal from '../../assets/js/init/modal';
  import messages from '../../assets/js/messages';
  import { mapActions } from 'vuex';

  export default {
    name: 'ConfirmationModal',
    data() {
      return {
        name: null,
        login: null,
        password: null,
        error: false,
        codeNumbers: {
          n1: null,
          n2: null,
          n3: null,
          n4: null,
        }
      };
    },
    mounted() {
      eventBus.$on('onConfirmViaCode', (data) => {
        this.name = data.name;
        this.login = data.login;
        this.password = data.password;
        this.type = data.type;
        this.clearData();
        modal.openModal('modal-confirmation-code');
      });
    },
    methods: {
      ...mapActions(['sendVerificationCodeForSignIn', 'sendVerificationCodeForPasswordChanging', 'signIn', 'signUp', 'changePassword']),
      setN1(event) {
        this.codeNumbers.n1 = event.data;
        this.$forceUpdate();
        this.$refs.code2.focus();
        this.checkAndSendCode();
      },
      setN2(event) {
        this.codeNumbers.n2 = event.data;
        this.$forceUpdate();
        this.$refs.code3.focus();
        this.checkAndSendCode();
      },
      setN3(event) {
        this.codeNumbers.n3 = event.data;
        this.$forceUpdate();
        this.$refs.code4.focus();
        this.checkAndSendCode();
      },
      setN4(event) {
        this.codeNumbers.n4 = event.data;
        this.$forceUpdate();
        this.$refs.code4.blur();
        this.checkAndSendCode();
      },
      checkAndSendCode() {
        this.error = false;
        if (this.codeNumbers.n1 === null || this.codeNumbers.n2 === null || this.codeNumbers.n3 === null || this.codeNumbers.n4 === null) {
          return;
        }
        let code = '' + this.codeNumbers.n1 + this.codeNumbers.n2 + this.codeNumbers.n3 + this.codeNumbers.n4;
        if (this.type === 'signUp') {
          this.sendVerificationCodeForSignIn({
            identifierValue: this.login,
            verificationCode: code,
            identifierTypeId: '54480ce1-00eb-4179-a2b6-f74daa6b9e72'
          })
            .then((result) => {
              if (result) {
                this.$logEvent(this.$analytics, 'registration_success');
                this.signIn({
                  login: this.login,
                  password: this.password
                })
                  .then(
                    (result) => {
                      this.$logEvent(this.$analytics, 'login_success');
                      this.$router.go()
                    }
                  );
              }
            })
            .catch(({ result }) => {
              this.error = true;
            });
        }
        if (this.type === 'changePassword') {
          this.changePassword({
            identifierValue: this.login,
            newPassword: this.password,
            verificationCode: code,
            identifierTypeId: '54480ce1-00eb-4179-a2b6-f74daa6b9e72'
          })
            .then((result) => {
              if (result) {
                this.showToast(this.$t('toast.password-changed'));
                modal.hideModal();
              }
            })
            .catch(({ result }) => {
              this.error = true;
            });

        }
      },
      clearData() {
        // this.password = null;
        this.email = null;
        this.error = false;
        this.codeNumbers.n1 = null;
        this.codeNumbers.n2 = null;
        this.codeNumbers.n3 = null;
        this.codeNumbers.n4 = null;
      },
      resendCode() {
        if (this.type === 'signUp') {
          this.signUp({
            login: this.login,
            name: this.name,
            password: this.password
          })
            .catch(error => {
              this.showToast(error.data.message, 'error');
            });
        }
        if (this.type === 'changePassword') {
          this.sendVerificationCodeForPasswordChanging(this.login);
        }
      },
      gotoLoginForm() {
        eventBus.$emit('onLoginModalOpen');
        eventBus.$emit('codeWaiting', false);
      }
    }
  };
</script>

<style scoped>

</style>
