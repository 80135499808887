import Vue from 'vue';
import VueRouter from 'vue-router';
import Objects from '../views/Objects.vue';
import routerHooks from './routerHooks';
import i18n from "../i18n";

Vue.use(VueRouter);

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
};

const routes = [
  {
    path: '/',
    name: 'objects',
    component: Objects,
    params: true,
    meta: {
      title: i18n.t('titles.default'),
    }
  },
  {
    path: '/goods/:id',
    name: 'goods',
    component: () => import('../views/Goods.vue'),
    meta: {
      title: i18n.t('titles.default'),
    }
  },
  {
    path: '/partner/:id',
    name: 'partner',
    component: () => import('../views/Partner.vue'),
    meta: {
      title: i18n.t('titles.default'),
    }
  },
  {
    path: '/donate/:id',
    name: 'donate',
    component: () => import('../views/Donate.vue'),
    meta: {
      title: i18n.t('titles.default'),
    }
  },
  {
    path: '/add-item',
    name: 'addItem',
    component: () => import('../views/AddItem.vue'),
    beforeEnter: routerHooks.ifAuthenticated,
    meta: {
      title: i18n.t('titles.default') + " — " + i18n.t('titles.add-item'),
    }
  },
  {
    path: '/edit-item/:id',
    name: 'editItem',
    component: () => import('../views/AddItem.vue'),
    beforeEnter: routerHooks.ifAuthenticated,
    meta: {
      title: i18n.t('titles.default') + " — " + i18n.t('titles.edit-item'),
    }
  },
  {
    path: '/add-partner',
    name: 'addPartner',
    component: () => import('../views/AddPartner.vue'),
    beforeEnter: routerHooks.ifAuthenticated,
    meta: {
      title: i18n.t('titles.default') + " — " + i18n.t('titles.add-partner'),
    }
  },
  {
    path: '/edit-partner/:id',
    name: 'editPartner',
    component: () => import('../views/AddPartner.vue'),
    beforeEnter: routerHooks.ifAuthenticated,
    meta: {
      title: i18n.t('titles.default') + " — " + i18n.t('titles.edit-partner'),
    }
  },
  {
    path: '/add-special',
    name: 'addSpecial',
    component: () => import('../views/AddSpecial.vue'),
    beforeEnter: routerHooks.ifAuthenticated,
    meta: {
      title: i18n.t('titles.default') + " — " + i18n.t('titles.add-special'),
    }
  },
  {
    path: '/chat/:id?',
    name: 'chat',
    component: () => import('../views/Chat.vue'),
    beforeEnter: routerHooks.ifAuthenticated,
    meta: {
      title: i18n.t('titles.default') + " — " + i18n.t('titles.chat'),
    }
  },
  {
    path: '/chat/new/:id?',
    name: 'newChat',
    component: () => import('../views/Chat.vue'),
    beforeEnter: routerHooks.ifAuthenticated,
    meta: {
      title: i18n.t('titles.default') + " — " + i18n.t('titles.new-chat'),
    }
  },
  {
    path: '/deals',
    name: 'deals',
    component: () => import('../views/Deals.vue'),
    beforeEnter: routerHooks.ifAuthenticated,
    meta: {
      title: i18n.t('titles.default') + " — " + i18n.t('titles.deals'),
    }
  },
  {
    path: '/oauth/:providerId',
    name: 'OAuth',
    component: () => import('../views/OAuth.vue'),
    params: true,
    meta: {
      title: i18n.t('titles.default'),
    }
  },
  {
    path: '/500',
    name: 'error500',
    component: () => import('../views/Error500.vue'),
    meta: {
      title: i18n.t('titles.default') + " — " + i18n.t('titles.error'),
    }
  },
  {
    path: '*',
    name: 'error404',
    component: () => import('../views/Error404.vue'),
    meta: {
      title: i18n.t('titles.default') + " — " + i18n.t('titles.error'),
    }
  },
  // не размещать здесь, изза path: '*', последующие роуты работать не будут
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
});

// не трогать код ниже
const originalPush = router.push
router.push = function push(location, onResolve, onReject)
{
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }

    return Promise.reject(err)
  })
}

router.beforeEach(routerHooks.beforeEach);
router.afterEach(routerHooks.afterEach);

export default router;
