<template>
  <div class="modal-wrapper modal-login" id="modal-change-email">
    <div class="modal">
      <div class="contents">
        <modal-close-button/>

        <div class="h1">{{ $t("modal.edit-profile.change-email") }}</div>
        <div class="h2">{{ $t("modal.edit-profile.enter-new-email") }}</div>
        <div class="form form-start">
          <form action="#" method="post" onsubmit="return false;">
            <div class="input">
              <input type="email" name="email" placeholder="E-mail" maxlength="255" :value="email" @input="onEmailInput" :class="{'err': error}">
              <i>E-mail</i>
            </div>
            <div class="message error" v-show="error">{{ $t("modal.edit-profile.email-is-exist") }}</div>
            <button class="btn" :disabled="!(reg.test(String(email).toLowerCase()) && email !== user.email) " @click="onChangeClicked">{{ $t("common.change") }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { eventBus } from '../../../main';
  import modal from '../../../assets/js/init/modal';
  import { mapActions, mapGetters } from 'vuex'
  import ModalCloseButton from '../../layouts/ModalCloseButton';

  export default {
    name: 'ProfileChangeEmailModal',
    components: { ModalCloseButton },
    data() {
      return {
        error: true,
        email: null,
        reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      }
    },
    computed: {
      ...mapGetters(['user'])
    },
    created() {
      eventBus.$on('emailEdit', () => {
        this.email = null;
        this.error = false;
        modal.openModal('modal-change-email');
      })
    },
    methods: {
      ...mapActions(['saveUserData']),
      onEmailInput(event) {
        this.email = event.target.value;
        this.error = false;
      },
      onChangeClicked() {
        this.saveUserData({ email: this.email })
          .then(result => {
            eventBus.$emit('onEditEmailConfirmation', this.email);
            this.email = null;
            this.error = false;
          })
          .catch((error) => {
            this.error = true;
            this.showToast(error.response.data.message, 'error');
          });
      }
    }
  };
</script>

<style scoped>

</style>
