<template>
  <v-layout/>
</template>

<script>
  import VLayout from "./layouts/VLayout.vue";
  import appInit from './assets/js/init/app';

  export default {
    components: {
      VLayout,
    },
    mounted() {
      appInit.init();
    }
  };
</script>

<style>

</style>
