<template>
  <main>
    <div class="main-banner" v-show="showBanner">
      <div class="holder">
        <div class="info">
          <div class="heading">
            {{ $t("main.banner-title") }}
          </div>
          <div class="text">
            <p>
              {{ $t("main.banner-text") }}
            </p>
          </div>
          <a href="https://promo.karmitt.com/" class="more">{{ $t("main.banner-button-text") }}</a>

          <div class="close" @click="closeBanner"></div>
        </div>
      </div>
    </div>
    <div class="holder">
      <!--<h1>{{ $t("main.goods") }}</h1>-->
      <h1>&nbsp;</h1>

      <div class="cols">
        <category-list @category-selected="changeViewCategory"></category-list>

        <section id="catalogue" class="col">
          <div class="tools-r">
            <div class="tabs">
              <ul id="view-type">
                <li class="list">
                  <a
                    v-on:click="switchViews('list')"
                    :class="{ 'tab-act': params.style === 'list' }"
                  >
                    <svg
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.36693 11.5104C7.66846 11.5104 8.71153 12.5637 8.71153 13.8745V17.0213C8.71153 18.3228 7.66846 19.3844 6.36693 19.3844H3.24695C1.95465 19.3844 0.902344 18.3228 0.902344 17.0213V13.8745C0.902344 12.5637 1.95465 11.5104 3.24695 11.5104H6.36693ZM17.0193 11.5104C18.3116 11.5104 19.3639 12.5637 19.3639 13.8745V17.0213C19.3639 18.3228 18.3116 19.3844 17.0193 19.3844H13.8993C12.5978 19.3844 11.5547 18.3228 11.5547 17.0213V13.8745C11.5547 12.5637 12.5978 11.5104 13.8993 11.5104H17.0193ZM6.36693 0.922852C7.66846 0.922852 8.71153 1.98441 8.71153 3.28689V6.4337C8.71153 7.74449 7.66846 8.79682 6.36693 8.79682H3.24695C1.95465 8.79682 0.902344 7.74449 0.902344 6.4337V3.28689C0.902344 1.98441 1.95465 0.922852 3.24695 0.922852H6.36693ZM17.0193 0.922852C18.3116 0.922852 19.3639 1.98441 19.3639 3.28689V6.4337C19.3639 7.74449 18.3116 8.79682 17.0193 8.79682H13.8993C12.5978 8.79682 11.5547 7.74449 11.5547 6.4337V3.28689C11.5547 1.98441 12.5978 0.922852 13.8993 0.922852H17.0193Z"
                      />
                    </svg>
                    <span>{{ $t("main.list") }}</span>
                  </a>
                </li>
                <li class="map">
                  <a
                    v-on:click="switchViews('map')"
                    :class="{ 'tab-act': params.style === 'map' }"
                  >
                    <svg
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.99345 0C13.6561 0 17.5 3.71789 17.5 8.31776C17.5 10.6357 16.657 12.7876 15.2695 14.6116C13.7388 16.6235 11.8522 18.3765 9.72854 19.7524C9.24251 20.0704 8.80387 20.0944 8.27045 19.7524C6.13474 18.3765 4.24809 16.6235 2.7305 14.6116C1.34198 12.7876 0.5 10.6357 0.5 8.31776C0.5 3.71789 4.34388 0 8.99345 0ZM8.99345 5.77683C7.45166 5.77683 6.19423 7.0478 6.19423 8.57675C6.19423 10.1177 7.45166 11.3297 8.99345 11.3297C10.5362 11.3297 11.8058 10.1177 11.8058 8.57675C11.8058 7.0478 10.5362 5.77683 8.99345 5.77683Z"
                      />
                    </svg>
                    <span>{{ $t("main.map") }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div
            id="view-type-list"
            v-if="params.style === 'list'"
            ref="goodsList"
          >
            <div id="search">
              <form onsubmit="return false;">
                <input
                  type="text"
                  name="keyword"
                  maxlength="255"
                  :placeholder="$t('main.search-noun')"
                  v-model="params.query"
                />
                <input
                  type="image"
                  :src="searchButton"
                  :alt="$t('main.search')"
                  @click="searchByQuery"
                />
                <div class="tool"></div>
              </form>
            </div>

            <ul class="items">
              <li
                v-for="(item, i) in objects"
                :key="i"
                :class="{
                  partner: item.type === 'partner'
                }"
              >
                <template v-if="item.type === 'goods'">
                  <a @click="openItem(item)">
                    <span class="title"
                      ><strong>{{ item.title }}</strong></span
                    >
                    <span
                      class="time-left"
                      :class="{ hot: item.expireDate < 3600000 }"
                      v-if="item.expireDate"
                      v-html="
                        $t('main.left', {
                          date: $options.filters.expireDateFilter(
                            item.expireDate
                          )
                        })
                      "
                    ></span>
                  </a>
                  <span
                    class="bg"
                    :style="
                      'background-image: url(' +
                        url +
                        'api/client/resource/' +
                        item.favoriteResourceId +
                        ')'
                    "
                  ></span>
                  <span
                    v-if="isAuthenticated"
                    class="selected"
                    :class="{ act: item.favorite }"
                    @click="addToFavorite(item)"
                  ></span>
                </template>

                <template v-if="item.type === 'donate'">
                  <a @click="openDonate(item)">
                    <span class="title"><strong>{{ item.description }}</strong></span>
                  </a>

                  <span
                    class="bg"
                    :style="
                      'background-image: url(' +
                        url +
                        'api/client/resource/' +
                        item.resourceIds[0] +
                        ')'
                    "
                  ></span>
                </template>

                <template v-else-if="item.type === 'partner'">
                  <a
                    :style="'background-color: ' + item.color"
                    @click="openPartner(item)"
                  >
                    <span class="logo-holder">
                      <img
                        :src="createPreviewResourceUrl(item.favoriteResourceId)"
                        :alt="item.title"
                        :title="item.title"
                      />
                    </span>
                    <span class="title">
                      <strong>{{ item.title }}</strong>
                      <span class="ico"
                        ><img
                          v-bind:src="
                            url +
                              'api/client/resource/' +
                              item.partnerCategory.resourceId
                          "
                          alt=""
                          style="-webkit-filter: grayscale(1); filter: grayscale(1);"
                      /></span>
                    </span>
                    <span class="actions"
                          v-show="item.specialIds && item.specialIds.length > 0"
                      >{{ $t("main.specials") }}:
                      {{
                        item.specialIds.length
                      }}</span
                    >
                    <span
                      v-if="isAuthenticated"
                      class="selected"
                      :class="{ act: item.favorite }"
                      @click="addToFavorite(item)"
                    ></span>
                  </a>
                </template>
              </li>
            </ul>
            <infinite-loading
              :distance="infiniteScrollDistance"
              forceUseInfiniteWrapper="true"
              :identifier="infinite"
              @infinite="loadObjects"
            >
              <div slot="spinner">
                <loading-progress
                  :progress="0"
                  :indeterminate="true"
                  :counter-clockwise="false"
                  :hide-background="false"
                  shape="circle"
                  :size="loadingIconSize"
                />
              </div>
              <div slot="no-more"></div>
              <div slot="no-results">
                <div class="empty">
                  <h2>{{ $t("main.nothing") }}</h2>
                  <p class="text">{{ $t("main.another-request") }}</p>
                </div>
              </div>
              <div slot="error"></div>
            </infinite-loading>
          </div>

          <div id="view-type-map" v-else>
            <google-map-loader
              id="map"
              apiKey="AIzaSyD94VydEISej3Y6hk7T0JmpxlmKITiEIDc"
              ref="goodsMapRef"
            />
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters, mapMutations } from "vuex";
import CategoryList from "../components/CategoryList.vue";
import expireDateFilter from "../assets/filters/expireDateFilter.js";
import InfiniteLoading from "vue-infinite-loading";
import { getLocation } from "../assets/js/geoUtils.js";
import { eventBus } from "../main";
import GoogleMapLoader from "@/components/GoogleMapLoader";

export default {
  name: "Home",
  title: "Home",
  components: { CategoryList, InfiniteLoading, GoogleMapLoader },
  filters: {
    expireDateFilter
  },
  data() {
    return {
      url: appSettings.baseUrl,
      searchButton: require("../../public/images/ico_search.svg"),
      infinite: +new Date(),
      infiniteScrollDistance: 600,
      previewLogo: require("../../public/images/icons/logo_gscale.svg"),
      loadingIconSize: 50,
      bannerFlag: true
    };
  },
  computed: {
    ...mapGetters({
      objects: "objects",
      params: "objectsParams",
      userGeo: "userGeo"
    }),
    ...mapGetters(["isAuthenticated"]),
    showBanner() {
      if(window.localStorage.getItem('showBanner')) return false;
      return this.bannerFlag;
    }
  },
  watch: {
    objects() {
      if (this.objects.length) {
        this.updateObjectsLastId(this.objects[this.objects.length - 1].id);
      }
    },
  },
  created() {
    eventBus.$on("reloadObjects", this.loadObjects);
  },
  async mounted() {
    let that = this;
    getLocation(location => {
      that.overwriteObjects([]);
      that.infinite++;
    });
    $(".items li .selected")
      .click(function() {
        if ($(this).hasClass("act"))
          $(this)
            .removeClass("act")
            .addClass("no-hover");
        else $(this).addClass("act");
      })
      .mouseleave(function() {
        $(this).removeClass("no-hover");
      });
    if ($("#item #photos").length) {
      // DEMO
      $("#item #photos .selected")
        .click(function() {
          if ($(this).hasClass("act"))
            $(this)
              .removeClass("act")
              .addClass("no-hover");
          else $(this).addClass("act");
        })
        .mouseleave(function() {
          $(this).removeClass("no-hover");
        });
    }
  },
  methods: {
    ...mapActions(["getObjectsList", "addToFavorite"]),
    ...mapMutations([
      "addObjects",
      "clearObjects",
      "overwriteObjects",
      "changeCategory",
      "search",
      "changeStyle",
      "updateObjectsLastId",
      "setLocation"
    ]),

    loaderIconInit() {
      let that = this;
      this.loadingIconSize = this.$refs.goodsList.clientWidth / 15;
      window.addEventListener("resize", function() {
        that.loadingIconSize = that.$refs.goodsList.clientWidth / 15;
      });
    },
    changeViewCategory(ids, favorite) {
      this.infinite++;

      if (this.$refs.goodsMapRef) {
        this.$refs.goodsMapRef.updateMap();
      }
    },
    async loadObjects($state) {
      const oldInfiniteId = this.infinite;
      let response = await this.getObjectsList({...this.params, ...this.userGeo});
      if (oldInfiniteId !== this.infinite) return;
      if (response.data.length) {
        this.addObjects(response.data);
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    searchByQuery() {
      this.search();
      this.infinite++;
    },
    openDonate(item) {
      this.$router.push({
        name: "donate",
        params: { id: item.id }
      });
    },

    openItem(item) {
      this.$router.push({
        name: "goods",
        params: { id: item.id, item: item }
      });
    },
    openPartner(item) {
      this.$router.push({
        name: "partner",
        params: { id: item.id, item: item }
      });
    },
    createNewItem() {
      this.$router.push({ name: "addItem" });
    },
    switchViews(tab) {
      this.changeStyle(tab);
    },
    createPreviewResourceUrl(id) {
      if (id) {
        return appSettings.baseUrl + "api/client/resource/" + id;
      } else {
        return this.previewLogo;
      }
    },
    closeBanner() {
       window.localStorage.setItem('showBanner', 'false');
       this.bannerFlag = false;
    }
  }
};
</script>
<style>
  .donate-title {
    top: 0;
  }
</style>
