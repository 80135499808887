import modal from './modal'

Number.prototype.pad = function(num) {
  let str = '';
  for(let i = 0; i < (num - this.toString().length); i++) str += '0';
  return str += this.toString();
}

export default {
  calendar(widget, data, onclick) {
    this.months = [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь'
    ];

    this.days_abr = [
      'Вс',
      'Пн',
      'Вт',
      'Ср',
      'Чт',
      'Пт',
      'Сб'
    ];

    this.construct = function () {
      let self = this;
      let original = widget.getElementsByClassName('active')[0];
      let selected = false;
      let now = new Date();

      let month_div;

      if (typeof original === 'undefined') {
        month_div = document.createElement('div');
        month_div.className = 'month';
        widget.appendChild(month_div);

        original = document.createElement('table');
        original.setAttribute('data-actual',
          data.getFullYear() + '/' +
          data.getMonth()
            .pad(2) + '/' +
          data.getDate()
            .pad(2));
        widget.appendChild(original);

      } else {
        month_div = widget.getElementsByClassName('month')[0];
        month_div.innerHTML = '';
      }

      let diff = data - new Date(original.getAttribute('data-actual'));

      diff = new Date(diff).getMonth();

      let table = document.createElement('table');

      table.className = diff === 0 ? 'to-left' : 'to-right';
      table.innerHTML = '';

      table.setAttribute('data-actual',
        data.getFullYear() + '/' +
        data.getMonth().pad(2) + '/' +
        data.getDate().pad(2));

      let heading = document.createElement('div');
      heading.className = 'heading';

      let btn_prev = document.createElement('button');
      btn_prev.className = 'btn-prev';
      btn_prev.innerHTML = '&#9666;';

      let btn_next = document.createElement('button');
      btn_next.className = 'btn-next';
      btn_next.innerHTML = '&#9656;';

      heading.innerHTML = self.months[data.getMonth()] + ' ' + data.getFullYear();
      month_div.appendChild(heading);
      month_div.appendChild(btn_prev);
      month_div.appendChild(btn_next);

      btn_prev.onclick = function (e) {
        e.preventDefault();
        data.setMonth(data.getMonth() - 1);
        self.calendar(widget, data);
      };

      btn_next.onclick = function (e) {
        e.preventDefault();
        data.setMonth(data.getMonth() + 1);
        self.calendar(widget, data);
      };

      widget.appendChild(month_div);
      widget.appendChild(table);

      let row = document.createElement('tr');
      for (let i = 1; i < 7; i++) {
        row.innerHTML += '<th>' + self.days_abr[i] + '</th>';
      }
      row.innerHTML += '<th>' + self.days_abr[0] + '</th>';
      table.appendChild(row);

      let monthBegin = new Date(data.getFullYear(), data.getMonth(), -1).getDay();

      let actual = new Date(data.getFullYear(),
        data.getMonth(),
        -monthBegin);

      for (let s = 0; s < 6; s++) {
        let row = document.createElement('tr');

        for (let d = 1; d < 8; d++) {
          let cell = document.createElement('td');
          let span = document.createElement('span');

          cell.appendChild(span);

          span.innerHTML = actual.getDate();

          if (actual.getMonth() !== data.getMonth()) {
            cell.className = 'inact';
          } else {
            cell.addEventListener('click', function () {
              if (selected !== false) selected.classList.remove('selected');
              this.classList.add('selected');
              selected = this;
            });
            cell.addEventListener('click', onclick);
          }

          //if(data.getDate() == actual.getDate() && data.getMonth() == actual.getMonth())
          //    cell.className = 'curr';
          if (now.getDate() == actual.getDate() && now.getMonth() == actual.getMonth() && now.getFullYear() == actual.getFullYear()) {
            cell.className = 'curr';
          }

          cell.setAttribute('data-date',
            actual.getFullYear() + '-' +
            actual.getMonth() + '-' +
            actual.getDate());

          actual.setDate(actual.getDate() + 1);
          row.appendChild(cell);
        }

        table.appendChild(row);
      }

      setTimeout(function () {
        table.className = 'active';
        original.className += diff === 0 ? ' to-right' : ' to-left';
      }, 20);

      original.className = 'inactive';

      setTimeout(function () {
        let inactives = document.getElementsByClassName('inactive');
        for (let i = 0; i < inactives.length; i++) {
          widget.removeChild(inactives[i]);
        }
      }, 1000);
    };

    this.construct();
  },

  init(){
    let that = this;
    let $formChooseDuration = $('#form-choose-duration');
    let $btnChooseDuration = $formChooseDuration.children('.btn');
    function chooseDurationCheck() {
      let date = $formChooseDuration.find('input[name="date"]').val();
      let time = $formChooseDuration.find('input[name="hours1"]').val() + $formChooseDuration.find('input[name="hours2"]').val() + $formChooseDuration.find('input[name="minutes1"]').val() + $formChooseDuration.find('input[name="minutes2"]').val();
      return date && time.length == 4;
    }
    $formChooseDuration.find('.js-calendar').each(function(i, cal) {
      that.calendar(cal, new Date(), function() {
        $formChooseDuration.find('input[name="date"]').val($(this).attr('data-date'));
        if (chooseDurationCheck()) $btnChooseDuration.removeAttr('disabled');
        else $btnChooseDuration.attr('disabled', 'disabled');
      });
    });
    $formChooseDuration.find('.time-holder2 input').on('keydown', function(e) {
      e.preventDefault();
      e.stopPropagation();

      let key;
      let keychar;
      if (window.event)
        key = window.event.keyCode;
      else if
      (e) key = e.which;
      else
        return true;

      // фикс для ввода через numpad
      if (key >= 96 && key <= 105) {
        key = key - 48;
      }

      keychar = String.fromCharCode(key);
      if ((key==null) || (key==0) || (key==8) || (key==9) || (key==13) || (key==27)) {
        let newval = $(this).val().slice(0, -1);
        $(this).val(newval).removeClass('filled');
        $(this).siblings('.colon').removeClass('filled');
        if (newval.length == 0) {
          if ($(this).next('input').length) {
            $(this).next('input').focus();
          } else {
            if ($(this).next('span').next('input').length) {
              $(this).next('span').next('input').focus();
            } else {
              $(this).blur();
            }
          }
        }

        if (chooseDurationCheck()) $btnChooseDuration.removeAttr('disabled');
        else $btnChooseDuration.attr('disabled', 'disabled');
        return true;
      }
      else if (('0123456789').indexOf(keychar) > -1) {
        let newval = $(this).val() + keychar;
        $(this).val(newval).addClass('filled');
        if (newval.length > 0) {
          if ($(this).next('input').length) {
            $(this).next('input').focus();
          } else {
            if ($(this).next('span').next('input').length) {
              $(this).next('span').next('input').focus();
            } else {
              $(this).blur();
            }
          }
          if (!$formChooseDuration.find('.time-holder2 input').not('.filled').length) {
            $(this).siblings('.colon').addClass('filled');
          }
        }

        if (chooseDurationCheck()) $btnChooseDuration.removeAttr('disabled');
        else $btnChooseDuration.attr('disabled', 'disabled');
        return true;
      }
      else
        return false;
    });
    $formChooseDuration.on('submit', function(e) {
      e.preventDefault();
      let now = new Date();

      $('#input-duration').val(duration);
    });
  }
};
