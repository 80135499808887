import ApiService from '@/assets/js/services/api.service';

export default {
  state: {},
  mutations: {},
  actions: {
    addToFavorite({ commit, dispatch, rootState }, item) {
      let object = rootState.objectsModule.objects.filter(obj => {
        return obj.id === item.id;
      })[0];
      if(object) {
        object.favorite = !object.favorite;
      }

      let deal = rootState.dealsModule.deals.filter(obj => {
        return obj.id === item.id;
      })[0];
      if(deal) {
        deal.favorite = !deal.favorite;
      }

      ApiService.post('api/client/goods/' + item.id + '/favorite', { favorite: item.favorite })
        .then()
        .catch(() => {
          if(object) {
            object.favorite = !object.favorite;
          }
          if(deal) {
            deal.favorite = !deal.favorite;
          }
        });
    },
    addToFavoriteSimplified({ commit, dispatch }, item) {
      return ApiService.post('api/client/' + item.type + '/' + item.id + '/favorite', { favorite: item.favorite });
    }
  },
  getters: {}
};
