<template>
  <div class="modal-wrapper" id="modal-choose-discount">
    <div class="modal">
      <div class="contents">
        <div class="modal-close" :title="$t('common.close')" @click="onModalClose"></div>
        <form action="#" method="post" id="form-choose-discount" onsubmit="return false;">
          <div class="h2">{{ $t("modal.discount.enter-number") }}</div>
          <div class="input">
            <input type="number" name="val" :placeholder="$t('modal.discount.enter')" autocomplete="off" :value="value" @input="onValueInput">
            <i>{{ $t("modal.discount.enter-number") }}</i>
          </div>

          <div class="btn-line">
            <button class="btn cancel js-cancel" @click="onModalClose">{{ $t("modal.discount.cancel") }}</button>
            <button class="btn" :disabled="!value" @click="onDiscountChose">{{ $t("modal.discount.save") }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import {eventBus} from '@/main.js';
  import modal from '../../assets/js/init/modal';
  import settings from '../../assets/js/settings';

  export default {
    name: 'DiscountModal',
    data () {
      return {
        value: null,
      }
    },
    methods: {
      onModalClose() {
        eventBus.$emit('onDiscountModalClose');
        //modal.hideModal(); // todo: дублирует инит скрипт, но что-то из инит скрипта не всегда отрабатывает
        setTimeout(() => this.value = null, settings.__animationSpeed);
      },
      onDiscountChose() {
        eventBus.$emit('onDiscountChose', this.value);
        modal.hideModal();
        setTimeout(() => {this.value = null}, settings.__animationSpeed)
      },
      onValueInput(event) {
        if(event.target.value < 1) {
          this.value = 1;
        } else if(event.target.value > 99) {
          this.value = 99;
        } else {
          this.value = event.target.value;
        }
        this.$forceUpdate()
      }
    }
  };
</script>

<style scoped>

</style>
