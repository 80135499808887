import ApiService from '@/assets/js/services/api.service';

export default {
  state: {
    deals: [],
    params: {
      style: 'list',
      limit: 20,
      lastId: null,
      deal: null,
      owner: true,
      needConfirmation: null
    },
    select: 'give',
  },
  mutations: {
    addDeals(state, payload) {
      state.deals.push(...payload);
    },
    clearDeals(state) {
      state.select = 'give';
      state.params = {
        style: 'list',
        limit: 20,
        lastId: null,
        deal: null,
        owner: true,
        needConfirmation: null
      };
      state.deals = [];
    },
    overwriteDeals(state, payload) {
      state.deals = payload;
    },
    updateDealsLastId(state, payload) {
      state.params.lastId = payload;
    },
    setGive(state) {
      state.select = 'give';
      state.params.lastId = null;
      state.params.deal = null;
      state.params.owner = true;
      state.params.needConfirmation = null;
      state.deals = [];
    },
    setTake(state) {
      state.select = 'take';
      state.params.lastId = null;
      state.params.deal = true;
      state.params.owner = false;
      state.params.needConfirmation = null;
      state.deals = [];
    },
    setConfirm(state) {
      state.select = 'confirm';
      state.params.lastId = null;
      state.params.deal = null;
      state.params.owner = null;
      state.params.needConfirmation = true;
      state.deals = [];
    }
  },
  actions: {
    async loadDeals({ commit }, params) {
      /*params = {
        style: 'list',
        limit: 20,
        owner: false,
        deal: true,
      };*/
      return await ApiService.get('api/client/goods', params);
    },
    confirmDeal({ commit, state }, params) {
      return ApiService.post('api/client/chats/' + params.id + '/confirm', params.body)
        .then(() => {
          for(let i = 0; i < state.deals.length; i++) {
            if(state.deals[i].id === params.id) {
              state.deals.splice(i, 1);
              return;
            }
          }
        });
    },
    bookingDeal({ commit, state }, params) {
      return ApiService.post('api/client/chats/' + params.id + '/book', params.body)
    },
  },
  getters: {
    deals(state) {
      return state.deals;
    },
    dealsParams(state) {
      return state.params;
    },
    select(state) {
      return state.select;
    }
  }
};
