<template>
  <div class="contents-edit contents-inner" id="profile-sidebar-edit-partner">
    <a href="#" class="backlink" data-toggle="#profile-sidebar-main">{{ $t('common.back') }}</a>

    <div class="partner-info internal">
      <div class="logo-holder">
        <img :src="createPartnerResourceUrl(partner.logoResourceId)" :alt="partner.name" :title="partner.name"></div>
      <div class="info">
        <div class="title">{{ partner.title }}</div>
        <div class="code">{{ partner.categoryName }}</div>
      </div>
    </div>

    <div class="partner-details">
      <div class="partner-text">
        <p style="white-space: pre-wrap;">{{ partner.description }}</p>
      </div>

      <!--<div id="map-partner-location"></div>-->

      <button class="btn" @click="onEditClick">{{ $t('sidebar.partner-edit.edit-data') }}</button>
    </div>

    <div class="sales">
      <div class="h2">{{ $t('sidebar.partner-edit.specials') }}</div>
      <div v-if="specials.length == 0" class="text">{{ $t('sidebar.partner-edit.no-specials') }}</div>
      <ul>
        <li v-for="item in specials" :key="item.id">
          <a class="js-modal-link" @click="onSpecialClicked(item)">
            <div class="ico discount" v-if="item.type === 'sale'">{{ item.sale }}%</div>
            <div class="ico" v-else/>
            <h3>{{ item.title }}</h3>
            <div class="karmitts">{{ item.cost }}</div>
          </a>
        </li>
      </ul>
      <a>
        <button class="btn" @click="onAddSpecialClick">{{ $t('sidebar.partner-edit.add-special') }}</button>
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import profileSideBar from '../../assets/js/init/profileSideBar';
  import { eventBus } from '../../main';

  export default {
    name: 'SidebarPartnerEditTab',
    computed: {
      ...mapGetters(['partner', 'specials'])
    },
    methods: {
      ...mapActions(['deleteSpecial', 'removeSpecial']),
      createPartnerResourceUrl(id) {
        if (id) {
          return appSettings.baseUrl + 'api/client/resource/' + id;
        } else {
          return require('../../../public/images/icons/logo_gscale.svg');
        }
      },
      onAddSpecialClick() {
        profileSideBar.hide();
        this.$router.push({name: 'addSpecial'})
      },
      onEditClick() {
        profileSideBar.hide();
        if(this.$route.name !== 'editPartner') {
          this.$router.push({name: 'editPartner', params: {id: this.partner.id, partner: this.partner}},)
        }
      },
      onSpecialClicked(item) {
        let that = this;
        eventBus.$emit('areYouSure', {
          text: this.$t('are-you-sure.delete-special'),
          yesFunc: function () {
            that.deleteSpecial({ id: item.id })
              .then(() => {
                that.removeSpecial({id: item.id})
                that.showToast(this.$t('toast.special-deleted'));
              })
              .catch(error => {
                that.showToast(error.data.message, 'error');
              });
          }
        })
      }
    }
  };
</script>

<style scoped>

</style>
