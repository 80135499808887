<template>
  <div class="modal-wrapper modal-confirm" id="modal-are-you-sure">
    <div class="modal">
      <div class="contents">
        <div class="h1">{{ text }}</div>

        <div class="btn-line">
          <button class="btn" @click="onYesClicked">{{ $t("common.yes") }}</button>
          <button class="btn cancel" @click="onCancelClicked">{{ $t("common.cancel") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { eventBus } from '../../main';
  import modal from '../../assets/js/init/modal';
  import settings from '../../assets/js/settings';

  export default {
    name: 'AreYouSureModal',
    data() {
      return {
        text: null,
        yesFunc: null,
      }
    },
    created() {
      eventBus.$on('areYouSure', data => {
        this.text = data.text;
        this.yesFunc = data.yesFunc;
        modal.openModal('modal-are-you-sure');
      })
    },
    methods: {
      onYesClicked() {
        modal.hideModal();
        this.yesFunc();
        setTimeout(() => {
          this.text = null;
          this.yesFunc = null;
        }, settings.__animationSpeed);
      },
      onCancelClicked() {
        modal.hideModal();
        setTimeout(() => {
          this.text = null;
          this.yesFunc = null;
        }, settings.__animationSpeed)

      }
    }
  };
</script>

<style scoped>

</style>
