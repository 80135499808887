<template>
  <div class="modal-wrapper" id="modal-choose-price">
    <div class="modal">
      <div class="contents">
        <div class="modal-close" :title="$t('common.close')" @click="onModalClose"></div>
        <form action="#" method="post" id="form-choose-price" onsubmit="return false;">
          <div class="h2">{{ $t('modal.price.enter-number') }}</div>
          <div class="input">
            <input type="number" name="val" :placeholder="$t('common.enter')" autocomplete="off" :value="value"
                   @input="onValueInput">
            <i>{{ $t('modal.price.enter') }}</i>
          </div>

          <div class="btn-line">
            <button class="btn cancel js-cancel" @click="onModalClose">{{ $t('common.cancel') }}</button>
            <button class="btn" :disabled="!value" @click="onPriceChose">{{ $t('common.save') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { eventBus } from '@/main.js';
  import modal from '../../assets/js/init/modal';
  import settings from '../../assets/js/settings';

  export default {
    name: 'PriceModal',
    data() {
      return {
        value: null,
      };
    },
    methods: {
      onModalClose() {
        eventBus.$emit('onPriceModalClose');
        setTimeout(() => this.value = null, settings.__animationSpeed);
      },
      onPriceChose() {
        eventBus.$emit('onPriceChose', this.value);
        modal.hideModal();
        setTimeout(() => {this.value = null}, settings.__animationSpeed)
      },
      onValueInput(event) {
        this.value = event.target.value;
        this.$forceUpdate();
      }
    }
  };
</script>

<style scoped>

</style>
