<template>
  <div class="contents-main contents-inner" id="profile-sidebar-main">
    <div class="h1">{{ $t('sidebar.main.profile') }}</div>

    <div class="main">
      <div class="img-holder">
        <img :src="createUserResourceUrl(user.resourceId)" :alt="user.name" :title="user.name" style="cursor: pointer" @click="onChangeAvatarClicked">
      </div>
      <div class="info">
        <div class="name">{{ user.name }}</div>
        <div v-if="user.email !== null && user.email.length > 0 && user.email != 'E-mail'" class="email">{{ user.email }}</div>
      </div>
      <button class="edit" data-toggle="#profile-sidebar-edit">{{ $t('sidebar.main.edit') }}</button>
    </div>

    <div class="tabs">
      <ul>
        <li><a href="#profile-information" class="tab-act">{{ $t('sidebar.main.info') }}</a></li>
        <li><a href="#profile-statistics">{{ $t('sidebar.main.stats') }}</a></li>
      </ul>
    </div>
    <div id="profile-information" class="tab-content">
      <div class="banner" v-if="user.promo != null">
        <a :href="user.promo.link" class="banner-main" target="_blank">
								<span class="banner-image">
									<img :src="createPartnerResourceUrl(user.promo.imageResourceId)">
								</span>
          <span class="banner-name">{{ user.promo.title }}</span>
        </a>
        <button type="button" class="banner-close" @click="onClosePromocodeClicked"></button>
      </div>

      <div v-show="user.type == 'partner'" class="partner-info" data-toggle="#profile-sidebar-edit-partner" style="cursor: pointer;" @click="becomePartner">
        <div class="logo-holder">
          <img :src="createPartnerResourceUrl(partner.logoResourceId)"
               :alt="partner.title"
               :title="partner.title"></div>
        <div class="info" v-if="partner.id !== ''">
          <div class="title">{{ partner.title }}</div>
          <div class="code">{{ $t('sidebar.main.partner-code') + ": "}}<strong>{{ partner.pointsCode }}</strong></div>
          <button class="edit">{{ $t('sidebar.main.edit-partner') }}</button>
        </div>
        <div class="info" v-else>
          <div class="code">{{ $t('sidebar.main.partner-profile') }}</div>
        </div>
      </div>

      <div class="mn-profile">
        <ul>
          <li>
            <a href="#" data-toggle="#profile-sidebar-enter-promocode">
              <img src="../../../public/images/ico_promocode.svg" :alt="$t('sidebar.main.promocode')" :title="$t('sidebar.main.promocode')">{{ $t('sidebar.main.promocode') }}</a>
          </li>
          <li>
            <a href="#" data-toggle="#profile-sidebar-feedback">
              <img src="../../../public/images/ico_feedback.svg" :alt="$t('sidebar.main.feedback')" :title="$t('sidebar.main.feedback')">{{ $t('sidebar.main.feedback') }}</a>
          </li>
          <li><a href="https://promo.karmitt.com/faq" target="_blank">
            <img src="../../../public/images/ico_conditions.svg" alt="F.A.Q."
                 title="F.A.Q."> F.A.Q.</a>
          </li>
          <li class="logout" @click="logoutMethod">
            <a href="#">
              <img src="../../../public/images/ico_logout.svg" :alt="$t('sidebar.main.logout')" :title="$t('sidebar.main.logout')">{{ $t('sidebar.main.logout') }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div id="profile-statistics">
      <div class="profile-rating">
        <div class="points"><strong>{{ user.balance }}</strong>karmitt</div>
        <div class="info">
          <div class="rank">{{ user.rating }}</div>
          <div class="label">{{ $t('sidebar.main.rating') }}</div>
        </div>
      </div>

      <div class="stats">
        <ul>
          <li class="given">
            <a href="#">{{ $t('sidebar.main.give') + " " }}<strong>{{ user.goodsGive }}</strong></a>
          </li>
          <li class="received">
            <a href="#">{{ $t('sidebar.main.take') + " " }}<strong>{{ user.goodsTake }}</strong></a>
          </li>
          <li class="donated">
            <a href="#">{{ $t('sidebar.main.donate') + " " }}<strong>{{ user.donated }}</strong></a>
          </li>
        </ul>
      </div>

      <div class="how-spend">
        <div class="h2">{{ $t('sidebar.main.how-spend') }}</div>

        <ul class="js-fadeout fadeout" data-mobile-only="true" data-max-width="1000">
          <li v-for="item in partnerCategories" :key="item.id">
            <a @click="onPartnerCategoryClicked(item.id)"><span class="icon-holder">
              <img style="max-width: 42px;" :src="createResourceUrl(item.resourceId)" :alt="item.name" :title="item.name"></span>{{item.name}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import ApiService from '@/assets/js/services/api.service';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import { eventBus } from '../../main';
  import profileSideBar from '../../assets/js/init/profileSideBar';
  import store from "../../store";

  export default {
    name: 'SidebarMainTab',
    data () {
      return {

      }
    },
    computed: {
      ...mapGetters(['user', 'partner', 'partnerCategories'])
    },
    created() {
        this.loadPartnerCategories();
    },
    methods: {
      ...mapActions(['logout', 'loadPartnerCategories']),
      ...mapMutations(['changeCategory']),
      logoutMethod() {
        this.logout(this.form)
          .then(resp => {
            this.$router.go();
          })
          .catch(err => console.warn(err));
      },
      createUserResourceUrl(id) {
        if (id) {
          return appSettings.baseUrl + 'api/client/resource/' + id;
        } else {
          return require('../../../public/images/icons/ico_account.jpg');
        }
      },
      createPartnerResourceUrl(id) {
        if (id) {
          return appSettings.baseUrl + 'api/client/resource/' + id;
        } else {
          return require('../../../public/images/icons/logo_gscale.svg');
        }
      },
      async getTermsAndConditions() {
        const links = await ApiService.get("/api/client/links", null);
        if(!links || !links.data) return;
        window.open(links.data.termsAndConditions);
      },
      becomePartner() {
          if (this.user.type == 'partner') {
              if (this.user.partnerId == '' || this.user.partnerId == undefined) {
                  this.$router.push({name: 'addPartner'});
              }
              else {
                  //Ничего не делаем, т.к. идёт проброс на карточку партнера через data-toggle
              }
          }
          else if (this.user.type == 'customer') {
              window.location = 'https://promo.karmitt.com/partners';
          }
      },
      onChangeAvatarClicked() {
        eventBus.$emit('avatarEdit');
      },
      createResourceUrl(id) {
        if (id) {
          return appSettings.baseUrl + 'api/client/resource/' + id;
        } else {
          return null;
        }
      },
      onPartnerCategoryClicked(itemId) {
        profileSideBar.hide();
        this.changeCategory({ids: ['81898709-d8d5-406e-ae7c-7a0a72b9eb13']})
        if(this.$route.name === 'main') {
          eventBus.$emit('reloadObjects');
        } else {
          this.$router.push({name: 'objects'});
        }

      },
      onClosePromocodeClicked() {
        let that = this;
        eventBus.$emit('areYouSure', {
          text: this.$t('are-you-sure.delete-promocode'),
          yesFunc: async function () {
            const response = await ApiService.delete('/api/client/user/promo');
            if(response != null && response.status != null && response.status == 200) {
              store.dispatch("loadUserInfo");
            }
          }
        })
      }
    }
  };
</script>

<style scoped>

</style>
