import ApiService from '@/assets/js/services/api.service';

export default {
  state: {},
  mutations: {},
  actions: {
    calculateDonatePoints({ commit, dispatch }, data) {
      return ApiService.post('api/client/donates/' + data.id + '/cashback', { currency: 'RUB', amount: data.value });
    },
    loadDonate({ commit, dispatch }, data) {
      return ApiService.get('api/client/donates/' + data.id);
    },
    pay({ commit, dispatch }, data) {
      return ApiService.post('api/client/donates/' + data.id + '/pay', { currency: 'RUB', amount: data.amount });
    },
    participate({ commit, dispatch }, data) {
      return ApiService.post('api/client/donates/' + data.id + '/participate', {});
    },
  },
  getters: {}
};
