import modal from './modal';
import messages from '../messages'
import eventBus from '@/main.js'

export default {
  init(checkAndSendFunction) {
    // FORM CONFIRMATION CODE
    let $formConfirmationCode = $('#form-confirmation-code');

    /*function confirmationCodeCheck() {
      let code = $formConfirmationCode.find('input[name="digit1"]')
        .val() + $formConfirmationCode.find('input[name="digit2"]')
        .val() + $formConfirmationCode.find('input[name="digit3"]')
        .val() + $formConfirmationCode.find('input[name="digit4"]')
        .val();
      messages.msgUnset($formConfirmationCode.find('.repeat'));
      $formConfirmationCode.find('input')
        .removeClass('err');
      if (code.length === 4) {
        $formConfirmationCode.find('input[name="digit4"]').blur();
        eventBus.$emit('sendCode', code);
      } else {
        messages.msgSetError($formConfirmationCode.find('.repeat'), 'Код введен неверно');
        $formConfirmationCode.find('input')
          .addClass('err');
        return false;
      }
    }*/

    $formConfirmationCode.find('input')
      .on('keydown', function (e) {
        e.preventDefault();
        e.stopPropagation();

        let key;
        let keychar;
        if (window.event) {
          key = window.event.keyCode;
        } else if
        (e) {
          key = e.which;
        } else {
          return true;
        }
        keychar = String.fromCharCode(key);
        if ((key == null) || (key == 0) || (key == 8) || (key == 9) || (key == 13) || (key == 27)) {
          $(this).val('');
          if ($(this).next('input').length) {
            $(this).next('input').focus();
          } else {
            // DEMO CHECK CODE
            checkAndSendFunction();
          }
          return true;
        } else if (('0123456789').indexOf(keychar) > -1) {
          $(this).val(keychar);
          if ($(this).next('input').length) {
            $(this).next('input').focus();
          } else {
            // DEMO CHECK CODE
            checkAndSendFunction();
          }
          return true;
        } else {
          return false;
        }
      });
  },
}
