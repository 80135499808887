import ApiService from '@/assets/js/services/api.service';

export default {
  state: {
    messages: {},
    complaints: [],
  },
  mutations: {
    addMessages(state, payload) {
      state.messages[payload.chatId].push(...payload.messages);
    },
    initMessages(state, payload) {
      if (!state.messages[payload]) {
        state.messages[payload] = [];
      }
    },
    addNewMessage(state, payload) {
      state.messages[payload.chatId].unshift(payload.message);
    }
  },
  actions: {
    loadComplaintCategories({ commit, state }) {
      return ApiService.get('api/client/complaint/categories');
    },
    loadChats({ commit, dispatch }, params) {
      return ApiService.get('api/client/chats', params);
    },
    loadChatById({ commit, dispatch }, params) {
      return ApiService.get('api/client/chats/' + params.id);
    },
    loadChatMessagesById({ commit, state }, params) {
      const messages = state.messages[params.id];
      if (messages.length) {
        const lastId = messages[messages.length - 1].id;
        if (lastId) params.query.lastId = lastId;
      }
      return ApiService.get('api/client/chats/' + params.id + '/messages', params.query);
    },
    loadNewChatMessagesById({ commit, state }, params) {
      const messages = state.messages[params.id];
      if (messages.length) {
        const lastId = messages[0].id;
        if (lastId) params.query.lastId = lastId;
      }
      return ApiService.get('api/client/chats/' + params.id + '/messages', params.query);
    },
    sendMessage({ commit, dispatch }, params) {
      return ApiService.post('api/client/chats/' + params.id + '/messages', params.body);
    },
    createChat({ commit, dispatch }, params) {
      return ApiService.post('api/client/chats', params.body);
    },
    blockChat({ commit }, params) {
      return ApiService.post('api/client/chats/' + params.id + '/block');
    },
    sendComplaints({ commit }, body) {
      return ApiService.post('api/client/complaint', body);
    },
  },
  getters: {
  }
};
