import ApiService from '@/assets/js/services/api.service';

export default {
  state: {
    objects: [],
    userGeo: {
      latitude: 55.751999, // мск
      longitude: 37.617734, // мск
    },
    gmapState: {
      lat: 55.751999, // мск
      lng: 37.617734, // мск
      zoom: 8,
    },
    params: {
      style: 'list',
      limit: 24,
      lastId: null,
      goodsCategoryIds: [],
      partnerCategoryIds: [],
      favorite: null,
       newest: null,
      latitude: null,
      longitude: null,
      query: "",
    },
  },
  mutations: {
    setLocation(state, payload) {
      state.params.latitude = payload.latitude;
      state.params.longitude = payload.longitude;
      state.params.lastId = null;
      state.objects = [];
    },
    setUserGeo(state, payload) {
      state.userGeo.latitude = payload.latitude;
      state.userGeo.longitude = payload.longitude;
      state.gmapState.lat = payload.latitude;
      state.gmapState.lng = payload.longitude;
    },
    addObjects(state, payload) {
      state.objects.push(...payload);
    },
    clearObjects(state) {
      state.params = {
        style: 'list',
        limit: 24,
        lastId: null,
        goodsCategoryIds: [],
        partnerCategoryIds: [],
        favorite: null,
         newest: null,
        query: "",
      };

      state.objects = [];
    },
    overwriteObjects(state, payload) {
      state.objects = payload;
      state.params.lastId = null;
    },
    changeCategory(state, payload) {
      state.params.lastId = null;
      state.objects = [];
      state.params.goodsCategoryIds = payload.ids;
      state.params.favorite = payload.favorite ? true : null;
      state.params.newest = payload.newest ? true : null;
    },

    addCategory(state, payload) {
      state.params.lastId = null;
      state.objects = [];
      state.params.goodsCategoryIds.push(payload);
    },

    // todo: duplicate
    /*setFavorite(state, payload) {
      if(state.objects.length >= payload.index && state.objects[payload.index].id === payload.id) {
        state.objects[payload.index].favorite = !state.objects[payload.index].favorite;
      }
    },*/

    setFavorite(state, payload) {
      state.params.lastId = null;
      state.objects = [];
      state.params.favorite = payload;
    },
    setNewest(state, payload) {
      state.params.lastId = null;
      state.objects = [];
      state.params.newest = payload;
    },

    removeCategory(state, payload) {
      state.params.lastId = null;
      state.objects = [];
      state.params.goodsCategoryIds.splice(payload, 1);
    },

    clearCategories(state) {
      state.params.lastId = null;
      state.objects = [];
      state.params.goodsCategoryIds = [];
    },

    saveLastMapPosition(state, payload) {
      state.gmapState.lat = payload.lat;
      state.gmapState.lng = payload.lng;
      state.gmapState.zoom = payload.zoom;
    },

    search(state, payload) {
      state.params.lastId = null;
      state.objects = [];
    },
    changeStyle(state, payload) {
      state.params.style = payload;
    },
    updateObjectsLastId(state, payload) {
      state.params.lastId = payload;
    },
  },
  actions: {
    async getObjectsList({ commit }, params) {
       return await ApiService.get('api/client/objects', params);
    },
  },
  getters: {
    objects(state) {
      return state.objects;
    },
    objectsParams(state) {
      return state.params;
    },
    userGeo(state) {
      return state.userGeo;
    },
    gmapState(state) {
      return state.gmapState;
    },
    selectedCategory(state) {
      return state.params.goodsCategoryIds;
    },
    favorite(state) {
      return state.params.favorite;
    },
    newest(state) {
      return state.params.newest;
    }
  }
};
