<template>
  <div class="modal-wrapper modal-upload-photo" id="modal-change-avatar">
    <div class="modal">
      <div class="contents">
        <modal-close-button/>

        <div class="h1">{{ $t("modal.edit-profile.choose-photo") }}</div>

        <div class="upload-files">
          <div class="field upload-files-field" id="upload-avatar-field">
            <p>{{ $t("modal.edit-profile.drag-photo") + " " + $t("common.or") + " "}} </p>
            <p><a href="#" class="js-upload-file">{{ $t("modal.edit-profile.choose-on-pc") }}</a></p>
            <form ref="avatarInput" enctype="multipart/form-data">
              <input type="file" name="photo" id="upload-avatar-input" accept="image/*" @change="uploadPhoto">
            </form>
            <ul>
              <li><a href="#" class="js-upload-file"><span>{{ $t("common.add") }}</span></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { eventBus } from '../../../main';
  import modal from '../../../assets/js/init/modal';
  import ModalCloseButton from '../../layouts/ModalCloseButton';
  import { mapActions, mapMutations, mapGetters } from 'vuex';

  export default {
    name: 'ProfileChangeAvatar',
    components: { ModalCloseButton },
    created() {
      eventBus.$on('avatarEdit', () => {
        modal.openModal('modal-change-avatar');
      })
    },
    mounted() {
      $('#upload-avatar-field .js-upload-file')
        .click(function (e) {
          e.preventDefault();
          $('#upload-avatar-input')
            .click();
        });
    },
    computed: {
      ...mapGetters(['user'])
    },
    methods: {
      ...mapActions(['uploadFile', 'saveUserData']),
      ...mapMutations(['setUserResourceId']),
      async uploadPhoto(event) {
        let file = event.target.files[0];
        this.$refs.avatarInput.reset();
        if (!file) {
          return;
        }

        modal.hideModalWithoutFadeout();
        let formData = new FormData();
        formData.append('file', file);
        await this.uploadFile(formData)
          .then((response) => {
            this.setUserResourceId({resourceId: response.data.id});
          })
            .catch(error => this.showToast(error.data.message, 'error'));
        this.saveUserData({ resourceId: this.user.resourceId })
          .catch(error => {
            this.setUserResourceId({resourceId: ''});
            this.showToast(error.data.message, 'error');
          });
      },
    }
  };
</script>

<style scoped>

</style>
