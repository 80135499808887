import store from "../store";
import modal from "../assets/js/init/modal";
import profileSideBar from "../assets/js/init/profileSideBar";
import Vue from "vue";
import VueRouter from "vue-router";

export default {
  ifNotAuthenticated(to, from, next) {
    if (!store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/");
  },

  ifAuthenticated(to, from, next) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/");
  },

  beforeEach(to, from, next) {
    store.dispatch("loadUserInfo");
    modal.hideModal();
    profileSideBar.hide();
    next();
  },

  afterEach(to, from) {
    let that = this;
    Vue.nextTick(() => {
      document.title = to.meta.title || "Karmitt";
    });
  }
};
