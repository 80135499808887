export default {
  init() {
    $('#profile').click(function(e) {
      e.preventDefault();
      e.stopPropagation();

      $('.contents-inner').hide()
        .filter('#profile-sidebar-main').show();

      $('#profile-sidebar').removeClass('hidden');
      $('#modal-fadeout').stop().fadeIn(300);
      $('html').addClass('html-modal');
    });
    $('#profile-sidebar').show()
      .find('.close').click(function(e) {
      e.preventDefault();
      e.stopPropagation();

      $('#profile-sidebar').addClass('hidden');
      $('#modal-fadeout').stop().fadeOut(300);
      $('html').removeClass('html-modal');
    });
    $('#profile-sidebar [data-toggle]').click(function(e) {
      e.preventDefault();

      $('.contents-inner').hide()
        .filter($(this).attr('data-toggle')).show();
    });
  },
  hide() {
    $('#profile-sidebar').addClass('hidden');
    $('#modal-fadeout').stop().fadeOut(300);
    $('html').removeClass('html-modal');
  }
}
