import ApiService from '@/assets/js/services/api.service';

export default {
  state: {
    specials: [],
  },
  mutations: {
    setSpecials: (state, payload) => {
      state.specials = payload;
    }
  },
  actions: {
    loadSpecialsAndCommit ({ commit, dispatch }, data) {
      commit('setSpecials', []);
      const payload = {
        partnerId: data.id
      };
      ApiService.get('/api/client/specials', payload)
        .then(result => {
          commit('setSpecials', result.data);
        })
    },
    loadSpecials ({ commit, dispatch }, data) {
      const payload = {
        partnerId: data.partnerId
      };
      return ApiService.get('/api/client/specials', payload);
    },
    loadPayload({commit}, data) {
      return  ApiService.get('/api/client/specials/' + data.id + '/payload')
    },
    saveSpecial({ commit, dispatch, rootState }, body) {
      body.partnerId = rootState.authModule.user.id;
      return ApiService.post('api/client/specials', body);
    },
    deleteSpecial({commit}, data) {
      return ApiService.delete('api/client/specials/' + data.id);
    },
    removeSpecial({state, commit}, data) {
      for(let i = 0; i < state.specials.length; i++) {
        if(state.specials[i].id === data.id) {
          state.specials.splice(i, 1);
          break;
        }
      }
    }
  },
  getters: {
    specials : state => state.specials,
  }
};
