import settings from '../settings';
import fadeout from './fadeout';

let resizeCallbacks = []

function onResize() {
  let prevWidth = $('html').data('width');
  $('html').data('width', $(window).width());

  settings.__isMobile = ($(window).width() <=  settings.displayUnits.widthMobile);
  settings.__isMobileTablet = ($(window).width() <= settings.displayUnits.widthMobileTablet);
  settings.__isMobileTabletMiddle = ($(window).width() <= settings.displayUnits.widthMobileTabletMiddle);
  settings.__isMobileTabletSmall = ($(window).width() <= settings.displayUnits.widthMobileTabletSmall);
  settings.__isMobileSmall = ($(window).width() <= settings.displayUnits.widthMobileSmall);


  if (prevWidth !== $(window).width()) {
    fadeout.fadeoutInit();
  }


  $.each(resizeCallbacks, function(i, func) {
    func();
  });
}

export default {
  init() {
    $(window).on('resize',function(){
      onResize();
    });
  },

  addToCallbacks(items) {
    resizeCallbacks.push(items)
  }
}
