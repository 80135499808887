<template>
  <div class="modal-wrapper" id="modal-login">
    <div class="modal">
      <div class="contents">
        <div class="modal-back" title="Back" v-show="step !== 'main'" @click="gotoPreviousForm"></div>
        <div class="modal-close" title="Close" @click="hideModal"></div>

        <div class="h1">{{ $t('modal.login.lets-meet') }}</div>
        <div class="h2">{{ $t('modal.login.sign-in-with-social-network') }}</div>
        <ul class="login-social-media">
          <li><a href="#"><img :class="{'disabled': this.providers.length === 0}" src="../../../public/images/ico_ss_vk.svg" alt="Sign in with VK"
                               title="Sign in with VK" @click="singInWithOAuthClicked('VK')" style="height: 53px;"></a></li>
          <li><a href="#"><img :class="{'disabled': this.providers.length === 0}" src="../../../public/images/ico_login_google.svg" alt="Sign in with Google"
                               title="Sign in with Google" @click="singInWithOAuthClicked('Google')"></a></li>
        </ul>

        <div class="h2" v-if="loginIsValid !== true || step === 'main'">{{ $t('modal.login.sign-in-with-email') }}</div>
        <div class="h2" v-else-if="loginIsValid && step === 'reg'">{{ $t('modal.login.enter-register-data') }}</div>
        <div class="form" id="form-start">
          <form action="#" method="post">
            <div class="input">
              <input type="email" name="email" placeholder="E-mail" maxlength="255" v-model="form.login">
            </div>
            <button class="btn" disabled="disabled" @click="onStartClicked">{{ $t('modal.login.start') }}</button>
          </form>
        </div>

        <div class="form hidden" id="form-authorisation">
          <form action="#" method="post">
            <div class="input input-clearing">
              <input type="email" name="email" placeholder="E-mail" maxlength="255" v-model="form.login">
              <span class="tool"></span>
            </div>
            <div class="input input-password hidden">
              <input type="password" name="password" :placeholder="$t('modal.login.password')" maxlength="255" v-model="form.password">
              <i>{{ $t("modal.login.password") }}</i>
              <span class="tool"></span>
            </div>
            <button class="btn" disabled="disabled" @click="onSignInClick">{{ $t("header.sign-in") }}</button>
            <a class="forget" @click="onChangePasswordClick">{{ $t("modal.login.forgot-password") }}</a>
          </form>
        </div>
        <div class="form hidden" id="form-registration">
          <form action="#" method="post">
            <div class="input">
              <input type="email" name="email" placeholder="E-mail" maxlength="255" disabled>
            </div>
            <div class="input">
              <input type="text" name="name" :placeholder="$t('modal.login.name')" maxlength="255" v-model="fields.name">
              <i>{{ $t("modal.login.name") }}</i>
            </div>
            <div class="input input-password hidden">
              <input type="password" name="password" :placeholder="$t('modal.login.password')" maxlength="255" v-model="form.password">
              <i>{{ $t("modal.login.new-password") }}</i>
              <span class="tool"></span>
            </div>
            <div class="input input-password hidden">
              <input type="password" name="password2" :placeholder="$t('modal.login.repeat-password')" maxlength="255" v-model="password2">
              <i>{{ $t("modal.login.repeat-password") }}</i>
              <span class="tool"></span>
            </div>
            <!-- ДЕМО ОШИБКИ
            <div class="message error">Пароли не совпадают</div>
            -->
            <button class="btn" disabled="disabled" @click="onSignUpClick">{{ $t("modal.login.sign-up") }}</button>
          </form>
        </div>
        <div class="conditions">{{ $t("modal.login.using-this-site") + " " }}
          <a :href="termsAndConditionLink" target="_blank">{{ $t("modal.login.terms") }}</a>
          {{ " " + $t("common.and") + " " }}
          <a :href="privacyPolicyLink" target="_blank">{{ $t("modal.login.policy") }}</a>
        </div>
        <!--<div class="continue"><a href="#" class="js-cancel">Продолжить без авторизации</a></div>-->
      </div>
    </div>
  </div>
</template>

<script>
  import ApiService from '@/assets/js/services/api.service';
  import { eventBus } from '@/main.js';
  import modal from '../../assets/js/init/modal.js';
  import { mapActions, mapGetters } from 'vuex';
  import loginModal from '../../assets/js/init/loginModal';
  import settings from '../../assets/js/settings';

  export default {
    name: 'LoginModal',
    data() {
      return {
         termsAndConditionLink: "",
         privacyPolicyLink: "",
        form: {
          login: null,
          password: null,
        },
        fields: {
          name: null,
        },
        password2: null,
        step: 'main'
      };
    },
    created() {
      this.loadOAuthProviders();
      this.getTermsAndConditions();
      this.getPrivacyPolicy();
      eventBus.$on('onLoginModalOpen', data => {
        this.form.login = null;
        this.form.password = null;
        this.fields.name = null;
        this.password2 = null;
        this.step = 'main';
        loginModal.clearModalState(true, 0);
        modal.openModal('modal-login')
      })
    },
    mounted() {
      modal.init();
      loginModal.init();
    },
    computed: {
      ...mapGetters(['loginIsValid', 'providers'])
    },
    methods: {
      ...mapActions(['checkLogin', 'signIn', 'signUp', 'loadOAuthProviders', 'singInWithOAuthCode']),
      clearLoginData() {
        setTimeout(() => {
          this.form.login = null;
          this.form.password = null;
          this.password2 = null;
          this.$store.commit('setLoginIsValid', { valid: null });
        }, settings.__animationSpeed);
      },

      singInWithOAuthClicked(providerName) {
        let provider = null;
        this.providers.forEach((item) => {
          if(item.name === providerName) {
            provider = item;
          }
        });

        if (provider !== null) {
            let url = provider.url + provider.id;
            console.log('url: ' + url);
            window.location = url;
        }
        else {
            this.showToast(this.$t('toast.roscompozor'), 'warning')
        }
      },
      onSignInClick(event) {
        event.preventDefault();
        this.signIn(this.form)
          .then(resp => {
            this.$logEvent(this.$analytics, 'login_success');
            this.$router.go();
          })
          .catch(error => {this.showToast(error.data.message, 'error')});
      },
      onSignUpClick(event) {
        event.preventDefault();
        this.signUp({login: this.form.login, name: this.fields.name, password: this.form.password})
          .then((result) => {
            modal.hideModal();
            eventBus.$emit('onConfirmViaCode', {name: this.fields.name, login: this.form.login, password: this.form.password, type: "signUp"});
            eventBus.$emit('codeWaiting', true);
            setTimeout(() => {
              this.form.login = null;
              this.form.password = null;
              this.password2 = null;
              this.fields.name = null;
            }, settings.__animationSpeed);
          })
          .catch(error => {this.showToast(error.data.message, 'error')});
      },
      onStartClicked(event) {
        event.preventDefault();
        this.checkLogin(this.form.login)
          .then((result) => {
            if(result) {
              this.step = 'reg'
            } else {
              this.step = 'auth'
            }
            loginModal.goToFullForm(!result);
          });
      },
      onChangePasswordClick() {
        //modal.hideModal();
        eventBus.$emit('onChangePassword', {login: this.form.login});
        this.form.login = null;
        this.form.password = null;
        this.fields.name = null;
      },
      hideModal() {
        setTimeout(() => {
          this.form.login = null;
          this.form.password = null;
          this.fields.name = null;
          loginModal.clearModalState(this.loginIsValid);
        }, settings.__animationSpeed);
      },
      async getTermsAndConditions() {
        const links = await ApiService.get("/api/client/links", null);
        if(!links || !links.data) return;
        this.termsAndConditionLink = links.data.termsAndConditions;
      },
      async getPrivacyPolicy() {
        const links = await ApiService.get("/api/client/links", null);
        if(!links || !links.data) return;
        this.privacyPolicyLink = links.data.privacyPolicy;
      },
      // логика выбора модалки для открытия в хеддере
      gotoPreviousForm() {
        loginModal.goToPrevForm(this.step);
        this.step = 'main';
        this.form.password = null;
        this.fields.name = null;
        this.password2 = null;
      }
    },
  };
</script>

<style scoped>
  img.disabled {
    cursor: default;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
</style>
