export default {
  fadeoutInit(node) {
    let $node = $(typeof (node) == 'undefined' ? 'body' : node);
    $node.find('.js-fadeout')
      .each(function (i, block) {
        if (!$(block)
          .data('inited')) {
          let $holder = $('<div class="fadeout-holder"></div>')
            .insertAfter($(block));
          $holder.html($(block));
          $(block)
            .data('inited', true);
        }

        if (typeof ($(block)
          .attr('data-nowrap')) != 'undefined' && $(block)
          .attr('data-nowrap') != false && $(block)
          .attr('data-nowrap') != 'false') {
          $(block)
            .addClass('nowrap');
        }
        $(block)
          .scrollLeft(0);
        let w_child = 0;
        let range = document.createRange();

        $.each(block.childNodes, function (i, node) {
          if (node.nodeType != 3) {
            w_child += $(node)
              .outerWidth(true);
          } else {
            if (typeof (range) != 'undefined') {
              range.selectNodeContents(node);
              let size = range.getClientRects();
              if (typeof (size) != 'undefined' && typeof (size[0]) != 'undefined' && typeof (size[0]['width'] != 'undefined')) w_child += size[0]['width'];
            }
          }
        });

        let maxWidth = $(block)
          .attr('data-max-width');
        let cloneWidth = $(block)
          .attr('data-clone-width');
        let mobileOnly = $(block)
          .attr('data-mobile-only');

        if (!mobileOnly || (mobileOnly && ($(window)
          .width() <= maxWidth))) {
          if (cloneWidth) {
            $(block)
              .width($(cloneWidth)
                .width());
          }
          let holderWidth = $(block)
            .width();
          if (w_child > holderWidth && (!maxWidth || $(window)
            .width() <= maxWidth)) {
            $(block)
              .addClass('fadeout')
              .removeClass('nowrap')
              .swipe({
                swipeStatus: function (event, phase, direction, distance) {
                  let offset = distance;

                  if (phase === $.fn.swipe.phases.PHASE_START) {
                    let origPos = $(this)
                      .scrollLeft();
                    $(this)
                      .data('origPos', origPos);

                  } else if (phase === $.fn.swipe.phases.PHASE_MOVE) {
                    let origPos = $(this)
                      .data('origPos');

                    if (direction == 'left') {
                      let scroll_max = $(this)
                        .prop('scrollWidth') - $(this)
                        .width();
                      let scroll_value_new = origPos - 0 + offset;
                      $(this)
                        .scrollLeft(scroll_value_new);
                      if (scroll_value_new >= scroll_max) {
                        $(this)
                          .addClass('scrolled-full');
                      } else {
                        $(this)
                          .removeClass('scrolled-full');
                      }

                    } else if (direction == 'right') {
                      let scroll_value_new = origPos - offset;
                      $(this)
                        .scrollLeft(scroll_value_new);
                      $(this)
                        .removeClass('scrolled-full');
                    }

                  } else if (phase === $.fn.swipe.phases.PHASE_CANCEL) {
                    let origPos = $(this)
                      .data('origPos');
                    $(this)
                      .scrollLeft(origPos);

                  } else if (phase === $.fn.swipe.phases.PHASE_END) {
                    $(this)
                      .data('origPos', $(this)
                        .scrollLeft());
                  }
                },
                threshold: 70,
                preventDefaultEvents: false
              });
          } else {
            $(block)
              .removeClass('fadeout');
          }
        }
      });
  }
}
