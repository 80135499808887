import Vue from 'vue';
import Vuex from 'vuex';

import objectsModule from './modules/objectsModule';
import donateModule from './modules/donateModule';
import goodsModule from './modules/goodsModule';
import dealsModule from './modules/dealsModule';
import categoriesModule from './modules/categoriesModule';
import authModule from './modules/authModule';
import favoriteModule from './modules/favoriteModule';
import resourceModule from './modules/resourceModule';
import specialModule from './modules/specialModule';
import chatModule from './modules/chatModule';
import partnerModule from './modules/partnerModule';
import userModule from './modules/userModule';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    authModule,
    categoriesModule,
    chatModule,
    dealsModule,
    favoriteModule,
    goodsModule,
    objectsModule,
    partnerModule,
    resourceModule,
    specialModule,
    userModule,
    donateModule
  }
});
