export default {
  name: 'Toast',
  data () {
    return {
      toastOptions: {
        type: 'success',
        duration: 3000,
        position: 'bottom-right'

      }
    }
  },
  methods: {
    showToast(message, type, queue = false) {
      this.$toast.open({
        message: message,
        type: type || this.toastOptions.type,
        queue: queue,
        // all of other options may go here
      });
    }
  }
};
