<template>
  <div class="contents-feedback contents-inner" id="profile-sidebar-enter-promocode">
    <a href="#" class="backlink" data-toggle="#profile-sidebar-main">{{ $t('common.back') }}</a>

    <div class="h1">{{ $t("sidebar.enter-promocode.title") }}</div>
      <div class="input">
        <textarea v-model="code" name="text" :placeholder="$t('sidebar.enter-promocode.code')" class="js-autoheight"></textarea>
        <i>{{ $t("sidebar.enter-promocode.code") }}</i>
      </div>
      <button @click="onApplyClicked" class="btn" :disabled="submitDisabled">{{ $t("sidebar.enter-promocode.apply") }}</button>
  </div>
</template>

<script>
  import ApiService from '@/assets/js/services/api.service';
  import store from "../../store";
  import {mapGetters} from "vuex";
  export default {
    name: 'SidebarEnterPromocodeTab',
    data() {
      return {
        code: "",
        isSending: false,
      }
    },
    created() {

    },
    computed: {
      submitDisabled() {
        return this.code.length == 0 || this.isSending;
      },
    },
    methods: {
      async onApplyClicked() {
        const data = {
          code: this.code
        }
        this.isSending = true;

        const response = await ApiService.post('/api/client/user/promo', data).catch(({ result }) => {
          this.showToast(this.$t('toast.promo-invalid'), "error");
        });
        this.isSending = false;
        if(response != null && response.status != null && response.status == 200) {
          store.dispatch("loadUserInfo");
          this.showToast(this.$t('toast.promo-activated'), "success");
        }
      },
    },
  };
</script>

<style scoped>

</style>
