<template>
  <div class="modal-x" :title="$t('common.close')" @click="onCloseModalClicked"></div>
</template>

<script>
  import modal from '../../assets/js/init/modal';

  export default {
    name: 'ModalCloseButton',
    methods: {
      onCloseModalClicked() {
        modal.hideModalWithoutFadeout();
      }
    }
  };
</script>

<style scoped>

</style>
