<template>
  <div class="modal-wrapper modal-forget" id="modal-change-password">
    <div class="modal">
      <div class="contents">
        <modal-close-button/>

        <div class="h1">{{ $t("modal.edit-profile.new-password") }}</div>
        <div class="h2">{{ $t("modal.edit-profile.enter-new-password") }}</div>
        <div class="form form-forget">
          <form action="#" method="post" onsubmit="return false;">
            <div class="input input-password hidden">
              <input type="password" name="password" :placeholder="$t('modal.edit-profile.new-password')" maxlength="255" v-model="password">
              <i>{{ $t("modal.edit-profile.new-password") }}</i>
              <span class="tool"></span>
            </div>
            <div class="message error" v-show="password !== null && password.length < 5">
              {{ $t("modal.edit-profile.min-password-length", {length: passwordLength}) }}
            </div>
            <div class="input input-password hidden">
              <input type="password" name="password2" :placeholder="$t('modal.edit-profile.repeat-new-password')" maxlength="255" v-model="password2">
              <i>{{ $t('modal.edit-profile.repeat-new-password') }}</i>
              <span class="tool"></span>
            </div>
            <div class="message error" v-show="password2 !== null && password !== password2">{{ $t('modal.edit-profile.passwords-are-not-same') }}</div>

            <button class="btn" :disabled="!(password !== null && password.length >= passwordLength && password === password2)" @click="onChangePasswordClicked">{{ $t('modal.edit-profile.change-password') }}</button>
          </form>
        </div>
        <a class="forget" @click="onCancelClicked">{{ $t('modal.edit-profile.dont-change-password') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import { eventBus } from '../../../main';
  import modal from '../../../assets/js/init/modal';
  import settings from '../../../assets/js/settings';
  import { mapActions, mapGetters } from 'vuex'
  import ModalCloseButton from '../../layouts/ModalCloseButton';

  export default {
    name: 'ProfileChangePasswordModal',
    components: { ModalCloseButton },
    data() {
      return {
        password: null,
        password2: null,
        passwordLength: 5,
      }
    },
    computed: {
      ...mapGetters(['user'])
    },
    created() {

    },
    mounted() {
      eventBus.$on('passwordEdit', data => {
        this.password = null;
        this.password2 = null;
        modal.openModal('modal-change-password');
      })
    },
    methods: {
      ...mapActions(['sendVerificationCodeForPasswordChanging']),
      onCancelClicked() {
        modal.hideModalWithoutFadeout();
        setTimeout(() => {
          this.password = null;
          this.password2 = null;
        }, settings.__animationSpeed);
      },
      onChangePasswordClicked() {
        this.sendVerificationCodeForPasswordChanging(this.user.email);
        eventBus.$emit('onEditPasswordConfirmation', this.password);
        setTimeout(() => {
          this.password = null;
          this.password2 = null;
        }, settings.__animationSpeed);
      }
    }
  };
</script>

<style scoped>

</style>
