import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "./jwt.service";
import {baseUrl} from "../../../appConfig";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = baseUrl;
        this.setHeader();
    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        let token = localStorage.getItem('id_token');
        if (token) {
            Vue.axios.defaults.headers.common["Token"] = token;
        }

        //Vue.axios.defaults.headers.common["Accept-Language"] = 'ru-RU';
    },

    query(resource, params) {
        return Vue.axios.get(resource, params);
    },

    get(resource, params) {
        let query = "";
        for (let k in params) {
            if (params[k] != null) {
                query += k + "=" + params[k] + '&';
            }
        }

        let url = resource;
        if (query.length > 0) {
            url += '?' + query;
        }
        return Vue.axios.get(url);
    },

    post(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    },

    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    delete(resource) {
        return Vue.axios.delete(`${resource}`);
    }
};

export default ApiService;
