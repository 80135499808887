import settings from '../settings';

function checkEmail(fld) {
  let reg = /^[a-zA-Z0-9_\.\-]+@([a-zA-Z0-9][a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}$/;
  let str = fld.value;

  $(fld).removeClass('err');
  if (!str || reg.test(str)) {
    return true;
  } else {
    $(fld).addClass('err');
    return false;
  }

}

export default {
  init() {
    // FORM START
    let $formStart = $('#form-start');
    let $formStartEmail = $formStart.find('input[name="email"]');
    let $formStartSubmit = $formStart.find('.btn');
    $formStartEmail.on('input', function () {
      if (checkEmail(this) && this.value != '') {
        $formStartSubmit.removeAttr('disabled');
      } else {
        $formStartSubmit.attr('disabled', 'disabled');
      }
    });

    // FORM AUTHORISATION
    let $formAuth = $('#form-authorisation');
    let $formAuthSubmit = $formAuth.find('.btn');
    $formAuth.find('input[name="email"], input[name="password"]')
      .on('input', function () {
        let $formAuthEmail = $formAuth.find('input[name="email"]');
        let $formAuthPswd = $formAuth.find('input[name="password"]');
        if (checkEmail($formAuthEmail.get(0)) && $formAuthPswd.val() != '') {
          $formAuthSubmit.removeAttr('disabled');
        } else {
          $formAuthSubmit.attr('disabled', 'disabled');
        }
      });

    // FORM REGISTRATION
    let $formReg = $('#form-registration');
    let $formRegSubmit = $formReg.find('.btn');
    $formReg.find('input[name="email"], input[name="name"], input[name="password"], input[name="password2"]' )
      .on('input', function () {
        let $formRegEmail = $formReg.find('input[name="email"]');
        let $formRegName = $formReg.find('input[name="name"]');
        let $formRegPswd = $formReg.find('input[name="password"]');
        let $formRegPswd2 = $formReg.find('input[name="password2"]');
        if (checkEmail($formRegEmail.get(0)) && $formRegName.val() != '' && $formRegPswd.val() != '' && $formRegPswd2.val() != '' && $formRegPswd.val() == $formRegPswd2.val()) {
          $formRegSubmit.removeAttr('disabled');
        } else {
          $formRegSubmit.attr('disabled', 'disabled');
        }
      });

    // FORM FORGET
    let $formForget = $('#form-forget');
    let $formForgetSubmit = $formForget.find('.btn');
    $formForget.find('input[name="password"], input[name="password2"]')
      .on('input', function () {
        let $formForgetPswd = $formForget.find('input[name="password"]');
        let $formForgetPswd2 = $formForget.find('input[name="password2"]');
        if ($formForgetPswd.val() != '' && $formForgetPswd2.val() != '' && $formForgetPswd.val() == $formForgetPswd2.val()) {
          $formForgetSubmit.removeAttr('disabled');
        } else {
          $formForgetSubmit.attr('disabled', 'disabled');
        }
      });

    // INPUTS
    $('.input-clearing, .input-password').each(function(i, inputbox) {
      $(inputbox).find('input').on('input', function() {
        var $tool = $(this).siblings('.tool');
        if (!$(this).val()) $tool.hide();
        else $tool.show();
      });
      $(inputbox).find('input').trigger('input');
    });
    $('.input-clearing .tool').click(function() {
      $(this).siblings('input').val('').trigger('input');
    });
    $('.input-password .tool').click(function() {
      var $inputbox = $(this).closest('.input');
      if ($inputbox.hasClass('hidden')) {
        $inputbox.removeClass('hidden').addClass('shown')
          .find('input').attr('type', 'text');
      } else {
        $inputbox.removeClass('shown').addClass('hidden')
          .find('input').attr('type', 'password');
      }
    });
  },

  goToFullForm(mayLogin) {
    let $formStart = $('#form-start');
    let $formStartEmail = $formStart.find('input[name="email"]');
    $formStart.slideUp(settings.__animationSpeed);
    if (mayLogin) {
      $('#form-authorisation')
        .slideDown(settings.__animationSpeed)
        .find('input[type="email"]')
        .val($formStartEmail.val())
        .trigger('input')
        .parent('.input')
        .addClass('focused');
    } else {
      $('#form-registration')
        .slideDown(settings.__animationSpeed)
        .find('input[type="email"]')
        .val($formStartEmail.val())
        .trigger('input')
        .parent('.input')
        .addClass('focused');
    }
  },

  clearModalState(isLoginForm, speed = settings.__animationSpeed) {
    if(isLoginForm === null) {
      return
    }

    $('#form-authorisation').slideUp(speed);
    $('#form-registration').slideUp(speed);

    $('#form-start')
      .slideDown(speed)
      .find('input[type="email"]')
      .trigger('input')
      .parent('.input')
      .addClass('focused');
  },

  goToPrevForm(currentForm) {
    let $formStart = $('#form-start');
    let $formAuthorisation =  $('#form-authorisation');
    let $formRegistration = $('#form-registration');
    let $formStartEmail = $formStart.find('input[name="email"]');

    if (currentForm === 'reg') {
      $formRegistration.slideUp(settings.__animationSpeed);
    } else {
      $formAuthorisation.slideUp(settings.__animationSpeed);
    }
    $formStart
      .slideDown(settings.__animationSpeed)
      .find('input[type="email"]')
      .val($formStartEmail.val())
      .trigger('input')
      .parent('.input')
      .addClass('focused');
  },
}
