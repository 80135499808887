<template>
    <div class="modal-wrapper" id="modal-participate">
        <div class="modal">
            <div class="contents">
                <div class="modal-close" :title="$t('common.close')"></div>

                <div class="h1">{{ $t('modal.participation.are-you-confirm', {participationCost: participationCost}) }}</div>

                <div class="btn-line">
                    <button class="btn cancel js-cancel">{{ $t("modal.participation.dont-confirm") }}</button>
                    <button class="btn" @click="onCheckoutClick">{{ $t("modal.participation.confirm") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {eventBus} from '../../main';
    import modal from '../../assets/js/init/modal';
    import {mapActions} from 'vuex';

    export default {
        name: 'ParticipationModal',
        data() {
            return {
                participationCost: 0,
            }
        },
        methods: {
            ...mapActions(['participate']),
                onCheckoutClick() {
                    let that = this;
                    this.participate({id: that.$route.params.id}).then((result) => {
                        this.$logEvent(this.$analytics, 'participate');
                        this.showToast(this.$t('donate.success-participation'), 'success');
                    }).catch(error => {
                        this.showToast(error.response.data.message, 'error');
                    });

                    modal.hideModal();
                }

        },
        created() {
            eventBus.$on('openParticipationModal', data => {
                this.participationCost = data.participationCost;
                modal.openModal('modal-participate')
            });
        },
    };
</script>

<style scoped>

</style>
