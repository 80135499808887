<template>
    <div class="modal-wrapper" id="modal-photo">
        <div class="modal">
            <div class="contents">
                <div class="modal-close" :title="$t('common.close')"></div>

                <img :src="url" alt="">
            </div>
        </div>
    </div>

</template>

<script>
    import {eventBus} from '../../main';
    import modal from '../../assets/js/init/modal';

    export default {
        name: 'PhotoModal',
        data() {
            return {
                url: ''
            }
        },
        created() {
            eventBus.$on('openPhotoModal', data => {
                this.url = data.url;
                modal.openModal('modal-photo');
            });
        },
        methods: {}
    };
</script>

<style scoped>

</style>
