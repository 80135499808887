<template>
  <div id="profile-sidebar" class="hidden">
    <div class="contents">

      <sidebar-main-tab/>
      <sidebar-profile-edit-tab/>
      <sidebar-partner-edit-tab/>
      <sidebar-feedback-tab/>
      <sidebar-enter-promocode-tab/>

      <div class="contents-done contents-inner" id="profile-sidebar-done">
        <div class="flex">
          <div class="h2">{{ $t("sidebar.sent") }}</div>

          <button class="btn" data-toggle="#profile-sidebar-main">{{ $t("sidebar.return-to-profile") }}</button>
        </div>
      </div>

      <div class="close" :title="$t('common.close')"></div>
    </div>
  </div>
</template>

<script>
  import {eventBus} from '../../main.js';
  import { mapActions, mapGetters } from 'vuex';
  import profileSideBar from '../../assets/js/init/profileSideBar';
  import SidebarMainTab from '../sidebarTabs/SidebarMainTab';
  import SidebarProfileEditTab from '../sidebarTabs/SidebarProfileEditTab';
  import SidebarFeedbackTab from '../sidebarTabs/SidebarFeedbackTab';
  import SidebarEnterPromocodeTab from '../sidebarTabs/SidebarEnterPromocodeTab';
  import SidebarPartnerEditTab from '../sidebarTabs/SidebarPartnerEditTab';
  import sidebarTabs from '../../assets/js/init/sidebarTabs';
  export default {
    name: 'ProfileSideBar',
    components: { SidebarPartnerEditTab, SidebarFeedbackTab, SidebarProfileEditTab, SidebarMainTab, SidebarEnterPromocodeTab },
    data() {
      return {
        partnerDefaultLogo: require('../../../public/images/icons/logo_gscale.svg'),
      }
    },
    computed: {
      ...mapGetters(['user', 'partner'])
    },
    mounted() {
      profileSideBar.init();
      sidebarTabs.init()
    },
    methods: {
      createUserResourceUrl(id) {
        if (id) {
          return appSettings.baseUrl + 'api/client/resource/' + id;
        } else {
          return null;
        }
      },
      createPartnerResourceUrl(id) {
        if (id) {
          return appSettings.baseUrl + 'api/client/resource/' + id;
        } else {
          return this.partnerDefaultLogo;
        }
      },
    },
  };
</script>

<style scoped>

</style>
