import {baseUrl} from "../../appConfig";

const __widthMobile = 1000;
const __widthMobileDesktop = 1440;
const __widthMobileDesktopSmall = 1150;
const __widthMobileTablet = 1000;
const __widthMobileTabletMiddle = 850;
const __widthMobileTabletSmall = 600;
const __widthMobileSmall = 540;

export default {
  __isMobile: ($(window).width() <= __widthMobile),
  __isMobileDesktopSmall: ($(window).width() <= __widthMobileDesktopSmall),
  __isMobileTablet: ($(window).width() <= __widthMobileTablet),
  __isMobileTabletMiddle: ($(window).width() <= __widthMobileTabletMiddle),
  __isMobileTabletSmall: ($(window).width() <= __widthMobileTabletSmall),
  __isMobileSmall: ($(window).width() <= __widthMobileSmall),

  __animationSpeed: 350,

  baseUrl: baseUrl,

  displayUnits: {
    widthMobile: __widthMobile,
    widthMobileDesktop: __widthMobileDesktop,
    widthMobileDesktopSmall: __widthMobileDesktopSmall,
    widthMobileTablet: __widthMobileTablet,
    widthMobileTabletMiddle: __widthMobileTabletMiddle,
    widthMobileTabletSmall: __widthMobileTabletSmall,
    widthMobileSmall: __widthMobileSmall
  }
}
