import settings from '../settings';

export default {
  init() {
    $('#mn-holder').click(function() {
      if (!$('body').hasClass('mobile-opened')) {
        if (!$(this).children('.close').data('inited')) {
          $(this).children('.close').click(function(e) {
            e.stopPropagation();
            $('#modal-fadeout').stop().fadeOut(300);

            setTimeout(function() {
              $('body').removeClass('mobile-opened');
            }, settings.__animationSpeed * 0.75);
            $('#search, #add-item').stop().fadeOut(settings.__animationSpeed / 3);
            $('#mn-holder').animate({'right': '-90%'}, settings.__animationSpeed, function() {
              $('#mn-holder').css('right', '0');
            });
          }).data('inited', true);
        }

        // если есть проблемы с бургером - они ниже
        if(settings.__isMobile) {
          $('#mn-holder').css('right', '-90%');
          $('body').addClass('mobile-opened');
          $('#mn-holder').find('.close').stop().show();
          $('#mn-holder').animate({'right': '0'}, settings.__animationSpeed);
          $('#search, #add-item').stop().delay(settings.__animationSpeed / 2).fadeIn(settings.__animationSpeed);

          if ($(this).children('ul').outerHeight() > $(window).height()) {
            $('html').addClass('html-mobile-long');
          } else {
            $('html').removeClass('html-mobile-long');
          }

          $('#modal-fadeout').stop().fadeIn(300);
        }

      }
    });
  }
}
