import settings from '../settings';

function showModal(modal_id, dontHideOthers) {
  let $modal = $('#' + modal_id);

  if (typeof(dontHideOthers) == 'undefined' || !dontHideOthers) $('.modal-wrapper:visible').not($modal).attr('data-transparent', true).stop().animate({'opacity': 0}, settings.__animationSpeed, function() {
    $(this).hide().css('opacity', 1);
  });

  let display = settings.__isMobileSmall ? 'block' : 'table';

  $('#modal-fadeout').stop().fadeIn(300);
  $modal.stop().fadeIn(450).css({
    'display': display,
    'top': $(window).scrollTop()
  });

  let oversize = $(window).height() < $modal.find('.contents').outerHeight(true) || $modal.attr('always-scrollable');

  if ($modal.attr('data-long') || oversize) {
    $('html').addClass('html-modal-long');

    if (oversize && settings.__isMobile) {
      let modalHeight = $modal.outerHeight() - parseInt($('#layout').css('padding-top'));
      $('#layout').data('scrollTop', $(window).scrollTop()).addClass('js-modal-overflow').height(modalHeight);
      $modal.css('top', 0);
      $('html,body').scrollTop(0);
    }
  } else {
    $('html').addClass('html-modal');
  }

  $modal.find('.js-scroll').each(function(index, block) {
    scrollInit(block);
  });
}

function scrollInit(block) {
  if (!$(block).data('inited')) {
    let maxHeight = $(block).attr('data-max-height');
    if (maxHeight < 0) maxHeight = $(block).parent().height() - Math.abs(maxHeight);
    if (maxHeight && $(block).outerHeight() > maxHeight) {
      $(block).css('max-height', maxHeight + 'px').jScrollPane({
          showArrows: false,
          mouseWheelSpeed: 20,
          autoReinitialise: true,
          verticalGutter: 0,
          verticalDragMinHeight: 36
        }
      );
    }
    $(block).data('inited', true);
  }
}


export default {
  init() {
    $('.js-modal-link').click(function(e) {
      e.preventDefault();
      showModal($(this).attr('href') ? $(this).attr('href').substring(1) : $(this).attr('data-target').substring(1));
    });
  },

  openModal(modalId) {
    showModal(modalId)
  },

   closeModalInit() {
    let that = this;
    let $element = $(typeof(element) != 'undefined' ? element : 'body');
    $element.find('.modal-close, .close-btn, .modal .js-cancel').click(function(e) {
      e.preventDefault();
      e.stopPropagation();
      that.hideModal(this, false);
    });
  },

  hideModal(sender, onlyModal) {
    let $modal = sender ? $(sender).closest('.modal-wrapper') : $('.modal-wrapper:visible');
    if (typeof(onlyModal) == 'undefined' || !onlyModal) {
      $('#modal-fadeout').stop().fadeOut(300);
      if ($('#layout').data('scrollTop')) {
        let savedScrollTop =$('#layout').data('scrollTop');
        $('#layout').removeClass('js-modal-overflow').height('auto').removeData('scrollTop');
        $('html,body').scrollTop(savedScrollTop);
      }
      $modal.stop().fadeOut(450, function() {
        $('html').removeClass('html-modal html-modal-long');
      });
    } else {
      $modal.stop().fadeOut(450);
    }
  },

  hideModalWithoutFadeout(sender, onlyModal) {
    let $modal = sender ? $(sender).closest('.modal-wrapper') : $('.modal-wrapper:visible');
    $modal.stop().fadeOut(450);
    /*if (typeof(onlyModal) == 'undefined' || !onlyModal) {
      console.log(1);
      if ($('#layout').data('scrollTop')) {
        console.log(2);
        let savedScrollTop =$('#layout').data('scrollTop');
        $('#layout').removeClass('js-modal-overflow').height('auto').removeData('scrollTop');
        $('html,body').scrollTop(savedScrollTop);
      }
      $modal.stop().fadeOut(450, function() {
        $('html').removeClass('html-modal html-modal-long');
      });
    } else {
      console.log(3);

    }*/
  },
}
