import ApiService from '@/assets/js/services/api.service';

export default {
  state: {},
  mutations: {},
  actions: {
    uploadFile({ commit, dispatch }, file) {
      return ApiService.post('api/client/resource', file);
    }
  },
  getters: {}
};
